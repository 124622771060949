import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { Money } from '@arrivage/model/dist/src/model';

const URL_PATTERN = /^(https:\/\/|http:\/\/)?(www\.)?([a-z0-9-]+\.[a-z]+)(.*)/;

export function phoneNumberValidator(phonePattern: RegExp): ValidatorFn {
  return (control: AbstractControl): { phoneNumber: string } | null => {
    let phoneNumber = phonePattern.test(control.value);
    if (!control.value) {
      phoneNumber = true;
    }
    return phoneNumber ? null : { phoneNumber: control.value as string };
  };
}

export function greaterThan(num: number): ValidatorFn {
  return (control: AbstractControl): { invalidNumber: number } | null => {
    return control.value > num
      ? null
      : { invalidNumber: control.value as number };
  };
}

export function lowerThan(num: number): ValidatorFn {
  return (control: AbstractControl): { invalidNumber: number } | null => {
    return control.value < num
      ? null
      : { invalidNumber: control.value as number };
  };
}

export function moneyValidator(currency: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    try {
      Money.fromDecimal(control.value, currency);
      return null;
    } catch (e) {
      return { invalidMoneyAmount: { value: control.value } };
    }
  };
}

export function priceValidator(currency: string): ValidatorFn {
  return Validators.compose([
    Validators.required,
    Validators.min(0),
    Validators.max(9999.99),
    moneyValidator(currency),
  ]);
}

export const MIN_QUANTITY = 0;
export const MAX_QUANTITY = 9999;
export function quantityValidator(): ValidatorFn {
  return Validators.compose([
    Validators.required,
    Validators.min(MIN_QUANTITY),
    Validators.max(MAX_QUANTITY),
  ]);
}

export const integerValidator: ValidatorFn = (
  control: AbstractControl
): { integer: string } | null => {
  let isInteger = /^[-]?[0-9]*$/.test(control.value);
  if (!control.value) {
    isInteger = true;
  }
  return isInteger ? null : { integer: control.value as string };
};

export const urlValidator: ValidatorFn = (
  control: AbstractControl
): { url: string } | null => {
  const value = control.value as string;

  if (!value) {
    return null;
  }

  if (URL_PATTERN.test(value)) {
    return null;
  } else {
    return { url: value };
  }
};

export const ALREADY_EXIST_ERROR_VALUE: string = 'alreadyExists';
export function notInList(values: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = (control.value as string).trim();
    if (values.includes(value)) {
      return { [ALREADY_EXIST_ERROR_VALUE]: { value } };
    } else {
      return null;
    }
  };
}

export const BLANK_SPACE_ERROR: string = 'blankName';
export function isOnlyBlankSpaces(): ValidatorFn {
  return (control: AbstractControl): { [errorKey: string]: any } => {
    const nameValue = control.value as string;
    const trimmedName = nameValue.trim();

    if (trimmedName.length === 0) {
      return { [BLANK_SPACE_ERROR]: { trimmedName } };
    } else {
      return null;
    }
  };
}

/**
 * FormGroup validator that checks if at least one element with an email is
 * selected or not
 *
 * Example `formGroupName.addValidators(atLeastOneSelectedMailRequired);`
 * @param group
 * @returns
 */
export function atLeastOneSelectedMailRequired(
  group: FormGroup
): ValidationErrors | null {
  if (group?.controls) {
    for (const control in group.controls) {
      if (
        group.controls.hasOwnProperty(control) &&
        group.controls[control].valid &&
        group.controls[control].value.email &&
        group.controls[control].value.isSelected
      ) {
        return null;
      }
    }
  }
  return { required: true };
}
