<ng-container *transloco="let t; read: 'upload-files'">
  <section
    class="files-container"
    fxLayout="row"
    fxLayoutGap="2rem"
    fxLayoutAlign="start center"
  >
    @if (editable) {
      <div class="file-drop">
        <button
          mat-raised-button
          class="big-btn bg-clickable"
          (click)="fileInput.click()"
        >
          <span fxLayout="column" fxLayoutAlign="center center">
            <mat-icon class="icon-display">file_upload</mat-icon>
            <span class="text-m wrap-pretty">{{ t('upload') }}</span>
          </span>
        </button>
        <input
          #fileInput
          id="file-input"
          type="file"
          [accept]="typeAccepted"
          (change)="onFileUpload($event)"
        />
      </div>
    }
    <div
      *ngFor="let file of displayedFiles"
      class="file-card"
      fxLayoutGap="1rem"
    >
      @if (editable) {
        <span class="close" (click)="deleteFile(file)"
          ><mat-icon>delete</mat-icon></span
        >
      }
      @if (!isPdf(file)) {
        <a [href]="file.url" target="_blank">
          <img class="img-preview" [src]="file.url" alt="Img" />
        </a>
      } @else {
        <div class="pdf-wrapper">
          <a [href]="file.url" target="_blank">
            <pdf-viewer
              class="pdf-preview"
              [src]="file.url | sanitize: 4"
              [render-text]="false"
              [page]="1"
              [show-all]="false"
              [original-size]="false"
            >
            </pdf-viewer>
          </a>
        </div>
      }
    </div>
  </section>
</ng-container>
