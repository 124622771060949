import _ from 'lodash';
import { PurchaseReportLine } from 'src/app/purchase-report/common/model/purchase-report.model';

import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as deliverySelectors from '@arrivage-distribution/customer/store/customer-delivery.selectors';
import { selectExternalCustomer } from '@arrivage-external/store/external-store.selectors';
import * as visibleOffersSelector from '@arrivage-offers/common/store/visible-offers.selectors';
import { PurchaseOrderTotalPipe } from '@arrivage-pipes/purchase-order-total-pipe/purchase-order-total.pipe';
import { PurchaseOrdersUtils } from '@arrivage-purchase-orders/common/util/purchase-orders.utils';
import { RelationsLogic } from '@arrivage-relationship/common/model/relations-logic.model';
import { selectCurrentRelationId } from '@arrivage-relationship/common/store/relationship.selectors';
import * as contextSelectors from '@arrivage-store/context/context.selectors';
import { State as RootState } from '@arrivage-store/state';
import {
  Delivery,
  Money,
  Offer,
  Organization,
  PurchaseOrder,
  PurchaseOrderStatus,
  WithId,
} from '@arrivage/model/dist/src/model';

import {
  PurchaseOrderCreationCustomerInfo,
  PurchaseOrderCreationStoreInfo,
  PurchaseOrderDashboardMetrics,
  PurchaseOrderEditionStoreInfo,
} from '../../common/model/purchase-orders.model';
import { createSelectors as createCommonPurchaseOrderSelectors } from '../../common/store/base-purchase-orders.selectors';
import { CustomerPurchaseOrdersState } from './customer-purchase-orders.state';

export const getCustomerPurchaseOrderState =
  createFeatureSelector<CustomerPurchaseOrdersState>('customerPurchaseOrders');

export const CustomerPurchaseOrdersSelectors =
  createCommonPurchaseOrderSelectors(getCustomerPurchaseOrderState);

export const selectOfferFromPurchaseOrder = createSelector<
  RootState,
  [PurchaseOrder & WithId, Dictionary<Offer & WithId>],
  Offer & WithId
>(
  CustomerPurchaseOrdersSelectors.selectActiveItem,
  visibleOffersSelector.selectEntities,
  (po, o) => {
    if (po && o) {
      return o[po.offerId];
    }
  }
);

export const selectCustomerInfo = createSelector<
  RootState,
  [Organization & WithId, string],
  PurchaseOrderCreationCustomerInfo
>(
  contextSelectors.getOrganization,
  selectCurrentRelationId,
  (organization, relationshipId) => {
    if (organization && relationshipId) {
      return {
        customer: {
          ...Organization.extractSummary(organization),
          organizationId: organization.id,
        },
        relationshipId: relationshipId,
      };
    }
  }
);

export const selectPublicCustomerInfo = createSelector<
  RootState,
  [Organization & WithId],
  PurchaseOrderCreationCustomerInfo
>(contextSelectors.getOrganization, (organization) => {
  if (organization) {
    return {
      customer: {
        ...Organization.extractSummary(organization),
        organizationId: organization.id,
      },
      relationshipId: null,
    };
  }
});

export const selectExternalCustomerCreationInfo = createSelector<
  RootState,
  [RelationsLogic.CustomerInfo, string],
  PurchaseOrderCreationCustomerInfo
>(
  selectExternalCustomer,
  selectCurrentRelationId,
  (customer, relationshipId) => {
    if (customer && relationshipId) {
      return {
        customer: customer,
        relationshipId: relationshipId,
      };
    }
  }
);

export const selectEditionCreationInfo = createSelector<
  RootState,
  [PurchaseOrder & WithId, (Delivery & WithId)[]],
  PurchaseOrderEditionStoreInfo
>(
  CustomerPurchaseOrdersSelectors.selectActiveItem,
  deliverySelectors.selectRelationshipDeliveries,
  (po, deliveries) => {
    if (po && deliveries) {
      return {
        purchaseOrder: po,
        currentDelivery: deliveries.find((x) => x.id === po.delivery?.id),
      };
    }
  }
);

export const selectPublicCreationInfo = createSelector<
  RootState,
  [Offer & WithId, string[]],
  PurchaseOrderCreationStoreInfo
>(
  visibleOffersSelector.selectActiveItem,
  contextSelectors.getOrganizationZones,
  (offer, zones) => {
    if (offer) {
      return {
        offer: offer,
        deliveries: _.uniqBy(
          [
            ...offer.vendorPublicDeliveries.filter(
              (d) => _.intersection(d.deliveryZoneCodes, zones).length > 0
            ),
          ],
          (d) => d.id
        ),
      };
    }
  }
);

export const selectCreationInfoFromRelation = createSelector<
  RootState,
  [PurchaseOrderCreationStoreInfo, (Delivery & WithId)[]],
  PurchaseOrderCreationStoreInfo
>(
  selectPublicCreationInfo,
  deliverySelectors.selectRelationshipDeliveries,
  (publicPurchaseOrderCreationStoreInfo, relationshipDeliveries) => {
    if (publicPurchaseOrderCreationStoreInfo && relationshipDeliveries) {
      return {
        ...publicPurchaseOrderCreationStoreInfo,
        deliveries: _.uniqBy(
          [
            ...publicPurchaseOrderCreationStoreInfo.deliveries,
            ...relationshipDeliveries,
          ],
          (d) => d.id
        ),
      };
    }
  }
);

export const selectAllCreationInfos = createSelector<
  RootState,
  [(Offer & WithId)[], (Delivery & WithId)[]],
  PurchaseOrderCreationStoreInfo[]
>(
  visibleOffersSelector.selectAll,
  deliverySelectors.selectAll,
  (offers, deliveries) => {
    return offers.map((offer) => {
      return {
        offer: offer,
        deliveries: deliveries.filter(
          (delivery) => delivery.organizationId === offer.vendor.organizationId
        ),
      };
    });
  }
);

export const getPurchaseOrderDashboardMetrics = createSelector<
  RootState,
  [(PurchaseOrder & WithId)[]],
  PurchaseOrderDashboardMetrics
>(
  CustomerPurchaseOrdersSelectors.selectSubmittedOrWaitingForApprovalPurchaseOrders,
  (purchaseOrders) => {
    const purchaseOrdersToConfirm = purchaseOrders.filter(
      (po) => po.status === PurchaseOrderStatus.MODIFIEDBYVENDOR
    );
    return {
      nbPurchaseOrdersToConfirm: {
        nb: purchaseOrdersToConfirm.length,
        total: Money.sum(
          purchaseOrdersToConfirm.map((po) =>
            new PurchaseOrderTotalPipe().transform(po)
          )
        ),
      },
    };
  }
);

const selectBuyingReportFromPurchaseOrderCallback = (
  purchaseOrders: (PurchaseOrder & WithId)[]
) => {
  if (purchaseOrders) {
    const salesReportLines = purchaseOrders
      .filter((purchaseOrder) =>
        PurchaseOrdersUtils.isDeliveredAndConfirmedOrCompleted(purchaseOrder)
      )
      .map((purchaseOrder) => {
        return purchaseOrder.orderItems.map((orderItem) => {
          return {
            purchaseOrder: purchaseOrder,
            orderItem: orderItem,
          } as PurchaseReportLine;
        });
      });
    return _.flatMap(salesReportLines);
  }
};

export const selectBuyingReportFromPurchaseOrderBySentDateRange =
  createSelector<RootState, [(PurchaseOrder & WithId)[]], PurchaseReportLine[]>(
    CustomerPurchaseOrdersSelectors.selectLoadedBySentDateRange,
    selectBuyingReportFromPurchaseOrderCallback
  );

export const selectBuyingReportFromPurchaseOrderByDeliveryOrPickupDateRange =
  createSelector<RootState, [(PurchaseOrder & WithId)[]], PurchaseReportLine[]>(
    CustomerPurchaseOrdersSelectors.selectLoadedByDeliveryOrPickupDateRange,
    selectBuyingReportFromPurchaseOrderCallback
  );

export const selectPurchaseOrderByRelationship = createSelector<
  RootState,
  [(PurchaseOrder & WithId)[]],
  Dictionary<(PurchaseOrder & WithId)[]>
>(CustomerPurchaseOrdersSelectors.selectAll, (po) =>
  _.groupBy(po, 'relationshipId')
);
