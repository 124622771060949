import { ProducersSelection } from '@arrivage-util/selection.model';
import {
  OrderItem,
  OrganizationSummary,
  Producer,
  PurchaseOrder,
  WithId,
} from '@arrivage/model/dist/src/model';

export interface ScheduledDelivery {
  purchaseOrders: (PurchaseOrder & WithId)[];
  deliveryId?: string;
  deliveryDate?: Date;
  pickupDate?: Date;
  pickupId?: string;
  areAllPurchaseOrdersBeenDelivered?: boolean;
}

export interface ScheduledDeliveryDashboardMetrics {
  nbTotalDeliveries: number;
  nbNextDeliveries: number;
  nbLateDeliveries: number;
  nextDeliveryDate: Date;
}

export interface ScheduledDeliveryFeedback {
  send_pick_split_list: string;
  generate_invoices_pdf: string;
}

export const ScheduledDeliveryFeedback: ScheduledDeliveryFeedback = {
  send_pick_split_list: 'send_pick_split_list',
  generate_invoices_pdf: 'generate_invoices_pdf',
};

export interface WithCustomer {
  customer: OrganizationSummary & WithId;
}

export interface CustomerLine {
  customer: OrganizationSummary & WithId;
  requestedQuantity: number;
}

export interface WithCustomerLines {
  customerLines: CustomerLine[];
}

export interface OrderItemsByCategory {
  categoryName: string;
  orderItems: (OrderItem & WithCustomerLines)[];
}

export interface OrderItemsByProducer {
  producerName: string;
  orderItemsByCategory: OrderItemsByCategory[];
}

export interface SendScheduledDeliveriesRecipient {
  email: string;
  pdfBase64: string;
  message?: string;
}

export interface SendPickSplitListDialogData {
  producers: (Producer & WithId)[];
  generalDeliveryMessageFromSettings?: string;
}

export interface SendPickSplitListResponseData extends ProducersSelection {
  includedDocuments: ScheduledDeliveriesPdfType[];
  deliveryMessage?: string;
}

export enum ScheduledDeliveriesPdfType {
  PICK_LIST = 'pick_list',
  DELIVERY_LIST = 'delivery_slip',
  PACK_LIST = 'pack_list',
  DELIVERY_REPORT = 'delivery_report',
}
