import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output, AfterContentInit,
} from '@angular/core';

import {
  DateRangeOption,
  DateRangePickerData,
} from '@arrivage-components/date-range-picker/date-range-picker.component';

export const DATE_TYPES = ['sent', 'deliveryOrPickup'] as const;
export type DateType = typeof DATE_TYPES[number];

@Component({
  selector: 'app-filtered-date-range-picker',
  templateUrl: './filtered-date-range-picker.component.html',
  styleUrls: ['./filtered-date-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilteredDateRangePickerComponent implements AfterContentInit {
  private readonly LOCAL_STORAGE_SUFFIX =
    '.saved-filtered-date-range-picker-informations';
  private readonly LOCAL_STORAGE_DATE_TYPE_SUFFIX = '.date-type';
  private readonly DEFAULT_DATE_TYPE = 'sent';

  public readonly DATE_TYPES = DATE_TYPES;

  public dateType: DateType;

  @Input()
  fillSpace: boolean = false;

  @Input()
  dateRangePickerLabel: string;

  @Input()
  dateRangePickerCustomTooltipText: string;

  @Input()
  pageName: string;

  @Input()
  defaultDateRange: Interval;

  @Input()
  defaultDateRangeOption: DateRangeOption;

  @Input()
  defaultDateType: DateType;

  @Output()
  dateRangeChanges = new EventEmitter<Interval>();

  @Output()
  dateTypeChanges = new EventEmitter<DateType>();

  constructor() {}

  ngAfterContentInit(): void {
    this.resetDateType();
    this.dateTypeChanges.emit(this.dateType);
  }

  resetDateType() {
    let storedDateRange: string;
    if (this.pageName) {
      storedDateRange = localStorage.getItem(
        this.pageName +
          this.LOCAL_STORAGE_SUFFIX +
          this.LOCAL_STORAGE_DATE_TYPE_SUFFIX
      );
    }
    if (storedDateRange) {
      this.dateType = storedDateRange as DateType;
    } else if (this.defaultDateType) {
      this.dateType = this.defaultDateType;
    } else {
      this.dateType = this.DEFAULT_DATE_TYPE;
    }
  }

  onDateRangeChanges(dateRangePickerData: DateRangePickerData) {
    if (dateRangePickerData.dateRangeValidity) {
      this.dateRangeChanges.emit(dateRangePickerData.dateRange);
    }
  }

  onDateTypeChanges(dateType: DateType) {
    this.dateType = dateType;
    this.dateTypeChanges.emit(this.dateType);
    if (!this.pageName) return;
    localStorage.setItem(
      this.pageName +
        this.LOCAL_STORAGE_SUFFIX +
        this.LOCAL_STORAGE_DATE_TYPE_SUFFIX,
      this.dateType
    );
  }
}
