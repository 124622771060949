import _ from 'lodash';
import {
  catchError,
  combineLatest,
  map,
  of,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

import { Logout } from '@arrivage-auth/store/auth.actions';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { getOrganization } from '@arrivage-store/context/context.selectors';
import { EntityFeedback } from '@arrivage-store/feedback/feedback-params.model';
import { createBaseEffects } from '@arrivage-store/generators';
import { State } from '@arrivage-store/state';
import { DateRangeLogic } from '@arrivage-util/date-range/date-range.logic';
import {
  Organization,
  PurchaseOrderStatus,
  WithId,
} from '@arrivage/model/dist/src/model';

import { BasePurchaseOrderService } from '../services/base-purchase-order.service';
import { PurchaseOrderActions } from './base-purchase-orders.actions';
import { PurchaseOrdersSelectors } from './base-purchase-orders.selectors';

export interface PurchaseOrderFeedback extends EntityFeedback {
  add_by_customer: string;
  add_external: string;
  add_from_public_offer: string;
  confirm_purchase_order: string;
  purchase_order_delivered: string;
  purchase_order_undelivered: string;
  invoice_paid: string;
  invoices_paid: string;
  invoice_unpaid: string;
  convert_to_invoice: string;
  send_purchase_order: string;
  load: string;
  query_by_date_range: string;
  update_many: string;
  unsupported_format_filte_type: string;
  add_test: string;
  duplicate_purchase_order_error: string;
  send_pick_split_list: string;
  send_pick_lists_and_update_emails: string;
  send_producers_sales_reports_and_update_emails: string;
  export_csv_error: string;
}

export const PurchaseOrderFeedback: PurchaseOrderFeedback = {
  update: 'update_purchase_order',
  update_many: 'update_many_purchase_orders',
  remove: 'remove_purchase_order',
  add: 'add_purchase_order',
  add_test: 'add_purchase_order_test',
  add_by_customer: 'add_purchase_order_by_customer',
  add_external: 'add_purchase_order_external',
  add_from_public_offer: 'add_purchase_from_public_offer',
  confirm_purchase_order: 'confirm_purchase_order',
  purchase_order_delivered: 'purchase_order_delivered',
  purchase_order_undelivered: 'purchase_order_undelivered',
  invoice_paid: 'invoice_paid',
  invoices_paid: 'invoices_paid',
  invoice_unpaid: 'invoice_unpaid',
  convert_to_invoice: 'convert_to_invoice',
  send_purchase_order: 'send_purchase_order',
  send_pick_split_list: 'send_pick_split_list',
  send_pick_lists_and_update_emails: 'send_pick_lists_and_update_emails',
  send_producers_sales_reports_and_update_emails:
    'send_producers_sales_reports_and_update_emails',
  //Error only
  load: 'load_purchase_orders',
  get_active_item: 'query_single_purchase_order',
  query: 'query_all_purchase_order',
  query_by_date_range: 'query_purchase_orders_by_date_range',
  unsupported_format_filte_type: 'unsupported_format_filte_type',
  duplicate_purchase_order_error: 'duplicate_purchase_order_error',
  export_csv_error: 'export_csv_error',
};

export function createBasePurchaseOrderEffects(
  actions$: Actions,
  store: Store<State>,
  purchaseOrdersActions: PurchaseOrderActions,
  selectors: PurchaseOrdersSelectors,
  service: BasePurchaseOrderService,
  snackbarService: SnackbarService,
  queryByDateRangeStatusFilter?: PurchaseOrderStatus[]
) {
  const baseEffects = createBaseEffects(
    actions$,
    store,
    purchaseOrdersActions,
    selectors,
    service,
    PurchaseOrderFeedback,
    snackbarService
  );
  return {
    ...baseEffects,
    queryByDateRangeGuard: createQueryByDateRangeGuardEffect(
      actions$,
      store,
      purchaseOrdersActions,
      selectors
    ),
    queryByDateRange: createQueryByDateRangeEffect(
      actions$,
      store,
      purchaseOrdersActions,
      service,
      selectors,
      queryByDateRangeStatusFilter
    ),
    displayQueryByDateRangeFailure: createDisplayQueryByDateRangeFailureEffect(
      actions$,
      purchaseOrdersActions,
      PurchaseOrderFeedback,
      snackbarService
    ),
  };
}

export function createQueryByDateRangeGuardEffect(
  actions$: Actions,
  store: Store<State>,
  actions: PurchaseOrderActions,
  selectors: PurchaseOrdersSelectors
) {
  return createEffect(() =>
    actions$.pipe(
      ofType(actions.queryByDateRangeGuard),
      withLatestFrom(
        store.select(selectors.selectStoredDateRanges),
        store.select(selectors.selectItems)
      ),
      map(([context, storedDateRanges, purchaseOrdersByDateType]) => {
        const dateRangesToQuery = DateRangeLogic.getDateRangesToQuery(
          storedDateRanges,
          context.newDateRange
        );
        if (dateRangesToQuery.length > 0) {
          return actions.queryByDateRange({
            newDateRange: context.newDateRange,
            newDateRangesToStore: DateRangeLogic.formatDateRanges([
              ...storedDateRanges,
              context.newDateRange,
            ]),
            newDateRangesToQuery: dateRangesToQuery,
          });
        } else {
          return actions.queryByDateRangeSuccess({
            items: purchaseOrdersByDateType,
            newDateRange: context.newDateRange,
            newDateRangesToStore: storedDateRanges || [],
          });
        }
      })
    )
  );
}

export function createQueryByDateRangeEffect(
  actions$: Actions,
  store: Store<State>,
  actions: PurchaseOrderActions,
  service: BasePurchaseOrderService,
  selectors: PurchaseOrdersSelectors,
  statusFilter?: PurchaseOrderStatus[]
) {
  return createEffect(() =>
    actions$.pipe(
      ofType(actions.queryByDateRange),
      withLatestFrom(
        store.select(getOrganization),
        store.select(selectors.selectItems)
      ),
      switchMap(([context, organization, purchaseOrdersByDateType]) => {
        return queryPurchaseOrders(
          context,
          service,
          organization,
          statusFilter
        ).pipe(
          takeUntil(actions$.pipe(ofType(Logout))),
          map((result) => {
            return actions.queryByDateRangeSuccess({
              items: {
                sent: _(purchaseOrdersByDateType.sent)
                  .concat(result.sent)
                  .uniqBy('id')
                  .value(),
                deliveryOrPickup: _(purchaseOrdersByDateType.deliveryOrPickup)
                  .concat(result.deliveryOrPickup)
                  .uniqBy('id')
                  .value(),
              },
              newDateRange: context.newDateRange,
              newDateRangesToStore: context.newDateRangesToStore,
            });
          }),
          catchError((e) => {
            reportError(e);
            return of(actions.queryByDateRangeFailure(e));
          })
        );
      })
    )
  );
}

export function createDisplayQueryByDateRangeFailureEffect(
  actions$: Actions,
  actions: PurchaseOrderActions,
  feedBackType: PurchaseOrderFeedback,
  snackbarService: SnackbarService
) {
  return createEffect(
    () =>
      actions$.pipe(
        ofType(actions.queryByDateRangeFailure),
        tap((x) => snackbarService.showError(feedBackType.query_by_date_range))
      ),
    { dispatch: false }
  );
}

function queryPurchaseOrders(
  context: {
    newDateRange: Interval;
    newDateRangesToStore: Interval[];
    newDateRangesToQuery: Interval[];
  } & TypedAction<string>,
  service: BasePurchaseOrderService,
  organization: Organization & WithId,
  statusFilter: PurchaseOrderStatus[]
) {
  return combineLatest(
    context.newDateRangesToQuery.map((dateRange) => {
      return service.list(organization.id, dateRange, null, statusFilter);
    })
  ).pipe(
    map((results) => {
      return _(results).reduce(
        (acc, result) => {
          return {
            sent: [...acc.sent, ...result.sent],
            deliveryOrPickup: [
              ...acc.deliveryOrPickup,
              ...result.deliveryOrPickup,
            ],
          };
        },
        {
          sent: [],
          deliveryOrPickup: [],
        }
      );
    })
  );
}
