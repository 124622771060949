import {
  addHours,
  addYears,
  endOfDay,
  isAfter,
  isBefore,
  isEqual,
  max,
  min,
  startOfDay,
  startOfToday,
  subHours,
} from 'date-fns';
import _ from 'lodash';
import RRule, { RRuleSet } from 'rrule';
import { DateUtils } from 'src/app/util/date.utils';

import { CustomerAccess } from '@arrivage-customer-access/customer-access.model';
import {
  DeliveryOption,
  WithDeliveryId,
} from '@arrivage-distribution/model/delivery.model';
import { DatesAvailability } from '@arrivage-price-lists/model/price-list.model';
import { DeliveryByRelationshipId } from '@arrivage-relationship/common/model/relationship.model';
import { NextDeliveryDatesOptions } from '@arrivage-util/delivery-date.model';
import { LangUtils } from '@arrivage-util/lang.utils';
import {
  Delivery,
  Money,
  TimeOfDay,
  TransactionDeliveryInfo,
  VendorDelivery,
  WeekDays,
  WithId,
} from '@arrivage/model/dist/src/model';

import { Bounds, CurrentDate } from './model/delivery.utils.model';

export namespace DeliveryUtils {
  export const TEN_YEARS = 10;
  export const MAX_HOURS = 24 * 365 * 10;
  export const DEFAULT_UPPER_BOUND = addYears(new Date(), TEN_YEARS);
  export const DEFAULT_NEXT_DELIVERY_DATES_OPTIONS: NextDeliveryDatesOptions = {
    upperBound: DeliveryUtils.DEFAULT_UPPER_BOUND,
    withDateTime: false,
  };

  export function createSingleDeliveryFromTransactionDeliveryInfo(
    transactionDeliveryInfo: TransactionDeliveryInfo,
    deliveryDate: Date,
    relationshipId: string,
    vendorOrganizationId: string,
    customerOrganizationId?: string
  ): Delivery & WithId {
    return {
      ...transactionDeliveryInfo,
      deliveryZoneCodes: [],
      organizationId: vendorOrganizationId,
      deliveryDates: [deliveryDate],
      deliverySchedule: null,
      name: '',
      visibleForOrganizations: customerOrganizationId
        ? [customerOrganizationId]
        : [],
      visibleForRelations: [relationshipId],
      isPublic: false,
    };
  }

  export function hasDeliveryZones(delivery: Delivery): boolean {
    return !!delivery && delivery.deliveryZoneCodes.length > 0;
  }

  export function allCustomersHaveDeliveryRoute(
    customers: CustomerAccess.Customers
  ): boolean {
    return (
      customers?.allowed.length === 0 ||
      customers?.allowed.every((c) => !!c.deliveryId)
    );
  }

  export function allCustomersHaveDeliveryDates(
    customers: CustomerAccess.Customers,
    deliveryByRelationship: DeliveryByRelationshipId,
    options: Bounds & CurrentDate = {}
  ): boolean {
    return (
      customers?.allowed.length === 0 ||
      customers?.allowed.every(
        (c) =>
          !c.deliveryId ||
          DeliveryUtils.hasOpenDeliveryDate(
            deliveryByRelationship[c.relationshipId],
            options
          )
      )
    );
  }

  export function convertTimestampFields<T extends VendorDelivery>(
    record: any
  ): T {
    if (!record) {
      return undefined;
    }

    const delivery: T = {
      ...record,
      deliveryDates: _.map(record.deliveryDates, (d) => DateUtils.toDate(d)),
    };

    if (record.deliverySchedule) {
      const from = record.deliverySchedule.from
        ? DateUtils.toDate(record.deliverySchedule.from)
        : null;

      const to = record.deliverySchedule.to
        ? DateUtils.toDate(record.deliverySchedule.to)
        : null;

      delivery.deliverySchedule = {
        ...record.deliverySchedule,
        from: from,
        to: to,
        excludedDates: _.map(record.deliverySchedule.excludedDates, (d) =>
          DateUtils.toDate(d)
        ),
      };
    }

    return delivery;
  }

  export function toUTC(date: Date) {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes()
      )
    );
  }

  export function fromUTC(date: Date) {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes()
    );
  }

  /**
   * Add the expedition time to a date.
   * @param {Date} date
   * @param {TimeOfDay} expeditionTime
   * @returns
   */
  export function addExpeditionTime(
    date: Date,
    expeditionTime: TimeOfDay
  ): Date | null {
    if (!date) return null;
    if (
      !expeditionTime ||
      LangUtils.nullOrUndefined(expeditionTime.hours) ||
      expeditionTime.hours < 0 ||
      expeditionTime.hours >= 24 ||
      LangUtils.nullOrUndefined(expeditionTime.minutes) ||
      expeditionTime.minutes < 0 ||
      expeditionTime.minutes >= 60
    )
      return date;
    return new Date(
      new Date(date).setHours(expeditionTime.hours, expeditionTime.minutes)
    );
  }

  /**
   * Add the lead time to a date.
   * @param date
   * @param LeadTimeHours
   * @returns
   */
  export function addLeadTime(date: Date, leadTimeHours: number): Date | null {
    if (!date) return null;
    if (LangUtils.nullOrUndefined(leadTimeHours) || leadTimeHours < 0)
      return date;
    return addHours(date, leadTimeHours);
  }
  /**
   * Returns true if the dates represent the same delivery date for the delivery.
   * @param {Pick<VendorDelivery, 'expeditionTime'>} delivery
   * @param {Date} firstDate
   * @param {Date} secondDate
   * @returns {boolean}
   */
  export function isSameDeliveryDate(
    delivery: Pick<VendorDelivery, 'expeditionTime'>,
    firstDate: Date,
    secondDate: Date
  ): boolean {
    return (
      !!delivery &&
      !!firstDate &&
      !!secondDate &&
      isEqual(startOfDay(firstDate), startOfDay(secondDate))
    );
  }

  /**
   * Get the status of the availability of the delivery dates for a group of customers.
   * @param {CustomerAccess.Customers} customers
   * @param {DeliveryByRelationshipId} deliveryByRel
   * @param {Bounds & CurrentDate} options
   * @returns {DatesAvailability}
   */
  export function getDeliveryAvailability(
    customers: CustomerAccess.Customers,
    deliveryByRel: DeliveryByRelationshipId,
    publicDeliveries: (Delivery & WithId)[],
    options: Bounds & CurrentDate = {}
  ): DatesAvailability {
    if (!customers) return DatesAvailability.NONE;
    if (!customers.allowed) return DatesAvailability.NONE;
    if (!deliveryByRel) return DatesAvailability.NONE;

    const deliveries = _(customers.allowed)
      .filter((customer) => !!customer.deliveryId && !!customer.relationshipId)
      .map((customer) => deliveryByRel[customer.relationshipId])
      .filter((delivery) => !!delivery)
      .value();

    const deliveriesWithPublicDeliveries = [...publicDeliveries, ...deliveries];

    if (deliveriesWithPublicDeliveries.length === 0)
      return DatesAvailability.NONE;
    else if (
      deliveriesWithPublicDeliveries.some((delivery) =>
        DeliveryUtils.hasOpenDeliveryDate(delivery, options)
      )
    )
      return DatesAvailability.AVAILABLE;
    else if (
      deliveriesWithPublicDeliveries.some((delivery) =>
        DeliveryUtils.hasDeliveryDate(delivery, options)
      )
    )
      return DatesAvailability.NONE_WITH_DELAY;
    return DatesAvailability.NONE;
  }

  /**
   * Generate a recurrence rule set for the delivery dates of a delivery.
   * @param {Pick<VendorDelivery, 'expeditionTime' | 'deliveryDates' | 'deliverySchedule'>} delivery
   * @param {Bounds} options
   * @returns {RRuleSet}
   */
  export function generateDeliveryDatesRecurrenceRuleSet(
    delivery: Pick<
      VendorDelivery,
      'expeditionTime' | 'deliveryDates' | 'deliverySchedule'
    >,
    options: Bounds = {}
  ): RRuleSet {
    // Set bounds
    const lowerbound = startOfDay(options.lowerBound ?? startOfToday());
    const upperBound = endOfDay(
      options.upperBound ?? addYears(lowerbound, TEN_YEARS)
    );

    // Convert dates to UTC
    const lowerBoundUTC = DeliveryUtils.toUTC(lowerbound);
    const upperBoundUTC = DeliveryUtils.toUTC(upperBound);
    const fixedDatesUTC = delivery.deliveryDates
      ?.map((date) => DeliveryUtils.toUTC(startOfDay(date)))
      ?.filter(
        (date) =>
          (isAfter(date, lowerBoundUTC) || isEqual(date, lowerBoundUTC)) &&
          (isBefore(date, upperBoundUTC) || isEqual(date, upperBoundUTC))
      );
    const excludedDatesUTC = delivery.deliverySchedule?.excludedDates
      ?.map((date) => DeliveryUtils.toUTC(startOfDay(date)))
      ?.filter(
        (date) =>
          (isAfter(date, lowerBoundUTC) || isEqual(date, lowerBoundUTC)) &&
          (isBefore(date, upperBoundUTC) || isEqual(date, upperBoundUTC))
      );
    const deliveryDaysUTC = delivery.deliverySchedule?.deliveryDays?.map(
      DeliveryUtils.convertWeekDayToNumber
    );
    const fromUTC = DeliveryUtils.toUTC(
      startOfDay(delivery.deliverySchedule?.from ?? lowerbound)
    );
    const toUTC = DeliveryUtils.toUTC(
      endOfDay(delivery.deliverySchedule?.to ?? upperBound)
    );

    const recurrenceRuleSet = new RRuleSet();

    if (fixedDatesUTC) {
      // Add fixed dates
      fixedDatesUTC.forEach((fixedDate) => recurrenceRuleSet.rdate(fixedDate));
    }

    if (deliveryDaysUTC && deliveryDaysUTC.length > 0 && fromUTC && toUTC) {
      // Add recurrent dates
      recurrenceRuleSet.rrule(
        new RRule({
          freq: RRule.WEEKLY,
          byweekday: deliveryDaysUTC,
          dtstart: max([fromUTC, lowerBoundUTC]),
          until: min([toUTC, upperBoundUTC]),
        })
      );

      if (excludedDatesUTC) {
        // Remove excluded dates
        excludedDatesUTC.forEach((excludedDate) =>
          recurrenceRuleSet.exdate(excludedDate)
        );
      }
    }

    return recurrenceRuleSet;
  }

  /**
   * Return all delivery dates for the delivery between lowerBound and upperBound
   * and add the expedition time to the dates.
   * @param {Pick<VendorDelivery, 'expeditionTime' | 'deliveryDates' | 'deliverySchedule'>} delivery
   * @param {Bounds} options
   * @returns {Date[]}
   */
  export function getAllDeliveryDates(
    delivery: Pick<
      VendorDelivery,
      'expeditionTime' | 'deliveryDates' | 'deliverySchedule'
    >,
    options: Bounds = {}
  ): Date[] {
    return DeliveryUtils.generateDeliveryDatesRecurrenceRuleSet(
      delivery,
      options
    )
      .all()
      .map((date) => DeliveryUtils.fromUTC(date))
      .map((date) =>
        DeliveryUtils.addExpeditionTime(
          startOfDay(date),
          delivery?.expeditionTime
        )
      );
  }

  /**
   * Convert a WeekDay to a number (0 = Monday, 6 = Sunday).
   * @param {WeekDay} day
   * @returns {number}
   */
  export function convertWeekDayToNumber(day: WeekDays): number {
    switch (day) {
      case WeekDays.MONDAY:
        return 0;
      case WeekDays.TUESDAY:
        return 1;
      case WeekDays.WEDNESDAY:
        return 2;
      case WeekDays.THURSDAY:
        return 3;
      case WeekDays.FRIDAY:
        return 4;
      case WeekDays.SATURDAY:
        return 5;
      case WeekDays.SUNDAY:
        return 6;
    }
  }

  /**
   * Returns true if the date is a delivery date for the delivery.
   * @param {Pick<VendorDelivery, 'expeditionTime' | 'deliveryDates' | 'deliverySchedule'>} delivery
   * @param {Date} date
   * @param {Bounds} options
   * @returns {boolean}
   */
  export function isDeliveryDate(
    delivery: Pick<
      VendorDelivery,
      'expeditionTime' | 'deliveryDates' | 'deliverySchedule'
    >,
    date: Date,
    options: Bounds = {}
  ): boolean {
    return (
      !!delivery &&
      !!date &&
      DeliveryUtils.getAllNextDeliveryDates(delivery, options).some(
        (deliveryDate) =>
          DeliveryUtils.isSameDeliveryDate(delivery, deliveryDate, date)
      )
    );
  }

  /**
   * Returns true if the date is an open delivery date for the delivery at currentDate.
   * @param {Pick<VendorDelivery, 'expeditionTime' | 'deliveryDates' | 'deliverySchedule' | 'minimumLeadTimeHours' | 'maximumLeadTimeHours'>} delivery
   * @param {Date} date
   * @param {Bound & CurrentDate} options
   * @returns {boolean}
   */
  export function isOpenDeliveryDate(
    delivery: Pick<
      VendorDelivery,
      | 'expeditionTime'
      | 'deliveryDates'
      | 'deliverySchedule'
      | 'minimumLeadTimeHours'
      | 'maximumLeadTimeHours'
    >,
    date: Date,
    options: Bounds & CurrentDate = {}
  ): boolean {
    return (
      !!delivery &&
      !!date &&
      DeliveryUtils.getAllNextOpenDeliveryDates(delivery, options).some(
        (deliveryDate) =>
          DeliveryUtils.isSameDeliveryDate(delivery, deliveryDate, date)
      )
    );
  }

  /**
   * Returns true if the delivery has a delivery date.
   * @param {Pick<VendorDelivery, 'expeditionTime' | 'deliveryDates' | 'deliverySchedule'>} delivery
   * @param {Bounds} options
   * @returns {boolean}
   */
  export function hasDeliveryDate(
    delivery: Pick<
      VendorDelivery,
      'expeditionTime' | 'deliveryDates' | 'deliverySchedule'
    >,
    options: Bounds = {}
  ): boolean {
    return !!DeliveryUtils.getNextDeliveryDate(delivery, options);
  }

  /**
   * Returns true if the delivery has an open delivery date at currentDate.
   * @param {Pick<VendorDelivery, 'expeditionTime' | 'deliveryDates' | 'deliverySchedule' | 'minimumLeadTimeHours' | 'maximumLeadTimeHours'>} delivery
   * @param {Bounds & CurrentDate} options
   * @returns {boolean}
   */
  export function hasOpenDeliveryDate(
    delivery: Pick<
      VendorDelivery,
      | 'expeditionTime'
      | 'deliveryDates'
      | 'deliverySchedule'
      | 'minimumLeadTimeHours'
      | 'maximumLeadTimeHours'
    >,
    options: Bounds & CurrentDate = {}
  ): boolean {
    return !!DeliveryUtils.getNextOpenDeliveryDate(delivery, options);
  }

  /**
   * Get the first delivery date.
   * @param {Pick<VendorDelivery, 'expeditionTime' | 'deliveryDates' | 'deliverySchedule'>} delivery
   * @param {Bounds} options
   * @returns {Date | undefined}
   */
  export function getNextDeliveryDate(
    delivery: Pick<
      VendorDelivery,
      'expeditionTime' | 'deliveryDates' | 'deliverySchedule'
    >,
    options: Bounds = {}
  ): Date | undefined {
    return DeliveryUtils.getNextDeliveryDates(delivery, 1, options)[0];
  }

  /**
   * Get the first open delivery date at currentDate.
   * @param {Pick<VendorDelivery, 'expeditionTime' | 'deliveryDates' | 'deliverySchedule' | 'minimumLeadTimeHours' | 'maximumLeadTimeHours'>} delivery
   * @param {Bounds & CurrentDate} options
   * @returns {Date | undefined}
   */
  export function getNextOpenDeliveryDate(
    delivery: Pick<
      VendorDelivery,
      | 'expeditionTime'
      | 'deliveryDates'
      | 'deliverySchedule'
      | 'minimumLeadTimeHours'
      | 'maximumLeadTimeHours'
    >,
    options: Bounds & CurrentDate = {}
  ): Date | undefined {
    return DeliveryUtils.getNextOpenDeliveryDates(delivery, 1, options)[0];
  }

  /**
   * Get the first delivery dates.
   * @param {Pick<VendorDelivery, 'expeditionTime' | 'deliveryDates' | 'deliverySchedule'>} delivery
   * @param {number} numberOfDates
   * @param {Bounds} options
   * @returns {Date[]}
   */
  export function getNextDeliveryDates(
    delivery: Pick<
      VendorDelivery,
      'expeditionTime' | 'deliveryDates' | 'deliverySchedule'
    >,
    numberOfDates: number,
    options: Bounds = {}
  ): Date[] {
    return DeliveryUtils.getAllNextDeliveryDates(delivery, options).slice(
      0,
      numberOfDates
    );
  }

  /**
   * Get the first open delivery dates at currentDate.
   * @param {Pick<VendorDelivery, 'expeditionTime' | 'deliveryDates' | 'deliverySchedule' | 'minimumLeadTimeHours' | 'maximumLeadTimeHours'>} delivery
   * @param {number} numberOfDates
   * @param {Bounds & CurrentDate} options
   * @returns {Date[]}
   */
  export function getNextOpenDeliveryDates(
    delivery: Pick<
      VendorDelivery,
      | 'expeditionTime'
      | 'deliveryDates'
      | 'deliverySchedule'
      | 'minimumLeadTimeHours'
      | 'maximumLeadTimeHours'
    >,
    numberOfDates: number,
    options: Bounds & CurrentDate = {}
  ): Date[] {
    return DeliveryUtils.getAllNextOpenDeliveryDates(delivery, options).slice(
      0,
      numberOfDates
    );
  }

  /**
   * Get all delivery dates.
   * @param {Pick<VendorDelivery, 'expeditionTime' | 'deliveryDates' | 'deliverySchedule'>} delivery
   * @param {Bounds} options
   * @returns {Date[]}
   */
  export function getAllNextDeliveryDates(
    delivery: Pick<
      VendorDelivery,
      'expeditionTime' | 'deliveryDates' | 'deliverySchedule'
    >,
    options: Bounds = {}
  ): Date[] {
    const lowerBound = options.lowerBound ?? startOfToday();
    const upperBound = options.upperBound ?? addYears(lowerBound, TEN_YEARS);
    return PrivateDeliveryUtils.getDeliveryDates(
      delivery,
      lowerBound,
      upperBound
    );
  }

  /**
   * Get all open delivery dates at currentDate.
   * @param {Pick<VendorDelivery, 'expeditionTime' | 'deliveryDates' | 'deliverySchedule' | 'minimumLeadTimeHours' | 'maximumLeadTimeHours'>} delivery
   * @param {Bounds & CurrentDate} options
   * @returns {Date[]}
   */
  export function getAllNextOpenDeliveryDates(
    delivery: Pick<
      VendorDelivery,
      | 'expeditionTime'
      | 'deliveryDates'
      | 'deliverySchedule'
      | 'minimumLeadTimeHours'
      | 'maximumLeadTimeHours'
    >,
    options: Bounds & CurrentDate = {}
  ): Date[] {
    const currentDate = options.currentDate ?? new Date();
    const lowerBound = options.lowerBound ?? startOfDay(currentDate);
    const upperBound = options.upperBound ?? addYears(lowerBound, TEN_YEARS);
    return PrivateDeliveryUtils.getOpenDeliveryDates(
      delivery,
      currentDate,
      lowerBound,
      upperBound,
      delivery?.minimumLeadTimeHours ?? 0,
      delivery?.maximumLeadTimeHours ?? MAX_HOURS
    );
  }

  /**
   * DELIVERY OPTIONS UTILS
   */
  export function isPublicDelivery(delivery: Delivery): boolean {
    return delivery.isPublic;
  }

  export function computeBestOption(
    deliveryOptions: (DeliveryOption & WithDeliveryId)[]
  ): (DeliveryOption & WithDeliveryId)[] {
    if (!deliveryOptions) return [];
    if (deliveryOptions.length < 2) return deliveryOptions;

    return [
      _.orderBy(
        deliveryOptions,
        [
          Money.display(deliveryOptions[0].minimumOrder).amount,
          Money.display(deliveryOptions[1].deliveryFee).amount,
        ],
        ['asc', 'asc']
      )[0],
    ];
  }

  export function isDeliveryOption(
    deliveries: (VendorDelivery & WithId)[],
    date: Date
  ): boolean {
    return (
      !!deliveries &&
      !!date &&
      DeliveryUtils.getAllNextDeliveryOptionsWithId(deliveries).some(
        (deliveryOption) => {
          const delivery = deliveries.find(
            (d) => !!d && d.id === deliveryOption.deliveryId
          );
          return (
            !!delivery &&
            DeliveryUtils.isSameDeliveryDate(
              delivery,
              deliveryOption.date,
              date
            )
          );
        }
      )
    );
  }

  export function isOpenDeliveryOption(
    deliveryOption: DeliveryOption,
    options: Bounds & CurrentDate = {}
  ): boolean {
    const currentDate = options.currentDate ?? new Date();

    let lowerBound: Date;
    if (deliveryOption.maximumLeadTimeHours) {
      lowerBound = subHours(
        deliveryOption.date,
        deliveryOption.maximumLeadTimeHours
      );
    } else {
      lowerBound = options.lowerBound ?? startOfDay(currentDate);
    }

    let upperBound: Date;
    if (deliveryOption.minimumLeadTimeHours) {
      upperBound = subHours(
        deliveryOption.date,
        deliveryOption.minimumLeadTimeHours
      );
    } else {
      upperBound = options.upperBound ?? addYears(lowerBound, TEN_YEARS);
    }

    return currentDate > lowerBound && currentDate < upperBound;
  }

  export function isOpenDeliveryDateFromDeliveries(
    deliveries: (VendorDelivery & WithId)[],
    date: Date,
    options: Bounds & CurrentDate = {}
  ): boolean {
    return (
      !!deliveries &&
      !!date &&
      DeliveryUtils.getAllOpenDeliveryOptionsWithId(deliveries, options).some(
        (deliveryOption) => {
          const delivery = deliveries.find(
            (d) => !!d && d.id === deliveryOption.deliveryId
          );
          return (
            !!delivery &&
            DeliveryUtils.isSameDeliveryDate(
              delivery,
              deliveryOption.date,
              date
            )
          );
        }
      )
    );
  }

  export function hasDeliveryOption(
    deliveries: VendorDelivery[],
    options: Bounds = {}
  ): boolean {
    return !!DeliveryUtils.getNextDeliveryOption(deliveries, options);
  }

  export function hasOpenDeliveryOption(
    deliveries: VendorDelivery[],
    options: Bounds & CurrentDate = {}
  ): boolean {
    return !!DeliveryUtils.getNextOpenDeliveryOption(deliveries, options);
  }

  /**
   * Get the next delivery option,
   * i.e. the next deliveryOption, open or not, at currentDate
   * @param deliveries
   * @param options
   * @returns
   */
  export function getNextDeliveryOption(
    deliveries: VendorDelivery[],
    options: Bounds & CurrentDate = {}
  ): DeliveryOption | undefined {
    return _.first(
      DeliveryUtils.getNextDeliveryOptions(deliveries, 1, options)
    );
  }

  /**
   * Get the next upcoming delivery option,
   * i.e. the next deliveryOption with a maximum lead time, not yet open
   * @param deliveries
   * @param options
   * @returns
   */
  export function getNextUpcomingDeliveryOption(
    deliveries: VendorDelivery[],
    options: Bounds & CurrentDate = {}
  ): DeliveryOption | undefined {
    return _.first(
      DeliveryUtils.getNextUpcomingDeliveryOptions(deliveries, 1, options)
    );
  }

  /**
   * Get the next open delivery option,
   * i.e. the next deliveryOption, open at currentDate
   * @param deliveries
   * @param options
   * @returns
   */
  export function getNextOpenDeliveryOption(
    deliveries: VendorDelivery[],
    options: Bounds & CurrentDate = {}
  ): DeliveryOption | undefined {
    return _.first(
      DeliveryUtils.getNextOpenDeliveryOptions(deliveries, 1, options)
    );
  }

  /**
   * Get the next delivery options,
   * i.e. delivery options, open or not, at currentDate
   * @param deliveries
   * @param numberOfDates
   * @param options
   * @returns
   */
  export function getNextDeliveryOptions(
    deliveries: VendorDelivery[],
    numberOfDates: number,
    options: Bounds & CurrentDate = {}
  ): DeliveryOption[] {
    return DeliveryUtils.getAllNextDeliveryOptions(deliveries, options).slice(
      0,
      numberOfDates
    );
  }

  /**
   * Get the next upcoming delivery options,
   * i.e. delivery options with a maximum lead time, not yet open
   * @param deliveries
   * @param numberOfDates
   * @param options
   * @returns
   */
  export function getNextUpcomingDeliveryOptions(
    deliveries: VendorDelivery[],
    numberOfDates: number,
    options: Bounds & CurrentDate = {}
  ): DeliveryOption[] {
    return DeliveryUtils.getAllUpcomingDeliveryOptions(
      deliveries,
      options
    ).slice(0, numberOfDates);
  }

  /**
   * Get the next open delivery options
   * i.e. the next delivery options, open at currentDate
   * @param deliveries
   * @param numberOfDates
   * @param options
   * @returns
   */
  export function getNextOpenDeliveryOptions(
    deliveries: VendorDelivery[],
    numberOfDates: number,
    options: Bounds & CurrentDate = {}
  ): DeliveryOption[] {
    return DeliveryUtils.getAllOpenDeliveryOptions(deliveries, options).slice(
      0,
      numberOfDates
    );
  }

  /**
   * Get all delivery options with id
   * i.e. delivery options with id, open or not, at currentDate
   * @param deliveries
   * @param numberOfDates
   * @param options
   * @returns
   */
  export function getNextDeliveryOptionsWithId(
    deliveries: (VendorDelivery & WithId)[],
    numberOfDates: number,
    options: Bounds & CurrentDate = {}
  ): (DeliveryOption & WithDeliveryId)[] {
    return DeliveryUtils.getAllNextDeliveryOptionsWithId(
      deliveries,
      options
    ).slice(0, numberOfDates);
  }

  /**
   * Get next open delivery options with id
   * i.e. delivery options with id, open at currentDate
   * @param deliveries
   * @param numberOfDates
   * @param options
   * @returns
   */
  export function getNextOpenDeliveryOptionsWithId(
    deliveries: (VendorDelivery & WithId)[],
    numberOfDates: number,
    options: Bounds & CurrentDate = {}
  ): (DeliveryOption & WithDeliveryId)[] {
    return DeliveryUtils.getAllOpenDeliveryOptionsWithId(
      deliveries,
      options
    ).slice(0, numberOfDates);
  }

  /**
   * Get next open delivery
   * i.e. delivery with the first next open delivery date
   * @param deliveries
   * @returns
   */
  export function getNextOpenDelivery(
    deliveries: (Delivery & WithId)[]
  ): Delivery & WithId {
    const deliverySortedByDate = deliveries
      .filter((delivery) => DeliveryUtils.hasOpenDeliveryDate(delivery))
      .sort(
        (a, b) =>
          DeliveryUtils.getNextOpenDeliveryDate(a)?.getTime() -
          DeliveryUtils.getNextOpenDeliveryDate(b)?.getTime()
      );
    return deliverySortedByDate[0];
  }

  /**
   * Get all next delivery options,
   * i.e. delivery options, open or not, at currentDate
   * @param deliveries
   * @param options
   * @returns
   */
  export function getAllNextDeliveryOptions(
    deliveries: VendorDelivery[],
    options: Bounds & CurrentDate = {}
  ): DeliveryOption[] {
    return DeliveryUtils.getAllNextDeliveryOptionsWithId(
      deliveries.map((d) => ({ ...d, id: undefined })),
      options
    ).map((d) => _.omit(d, 'deliveryId'));
  }

  /**
   * Get all upcoming delivery options,
   * i.e. delivery options with a maximum lead time, not yet open
   * @param deliveries
   * @param options
   * @returns
   */
  export function getAllUpcomingDeliveryOptions(
    deliveries: VendorDelivery[],
    options: Bounds & CurrentDate = {}
  ): DeliveryOption[] {
    if (!deliveries) return [];

    const currentDate = options.currentDate ?? new Date();
    const lowerBound = options.lowerBound ?? startOfDay(currentDate);
    const upperBound = options.upperBound ?? addYears(lowerBound, TEN_YEARS);
    return deliveries
      .flatMap((delivery) => {
        if (
          !delivery.maximumLeadTimeHours ||
          delivery.maximumLeadTimeHours <= 0 ||
          delivery.maximumLeadTimeHours >= MAX_HOURS
        )
          return [];
        return PrivateDeliveryUtils.getOpenDeliveryDates(
          delivery,
          currentDate,
          lowerBound,
          upperBound,
          delivery?.maximumLeadTimeHours, // minimumLeadTimeHours = maximumLeadTimeHours to get delivery dates not yet open
          MAX_HOURS
        ).map<DeliveryOption>((date) => ({
          date: date,
          minimumOrder: delivery.minimumOrder,
          deliveryFee: delivery.deliveryFee,
          freeDeliveryAbove: delivery.freeDeliveryAbove,
          minimumLeadTimeHours: delivery.minimumLeadTimeHours,
          maximumLeadTimeHours: delivery.maximumLeadTimeHours,
        }));
      })
      .sort((a, b) => a.date.getTime() - b.date.getTime());
  }

  /**
   * Get all open delivery options,
   * i.e. delivery options, open at currentDate
   * @param deliveries
   * @param options
   * @returns
   */
  export function getAllOpenDeliveryOptions(
    deliveries: VendorDelivery[],
    options: Bounds & CurrentDate = {}
  ): DeliveryOption[] {
    if (!deliveries) {
      return [];
    }
    return DeliveryUtils.getAllOpenDeliveryOptionsWithId(
      deliveries.map((d) => ({ ...d, id: undefined })),
      options
    ).map((d) => _.omit(d, 'deliveryId'));
  }

  /**
   * Get all next delivery options with id,
   * i.e. delivery options with id, open or not, at currentDate
   * @param deliveries
   * @param options
   * @returns
   */
  export function getAllNextDeliveryOptionsWithId(
    deliveries: (VendorDelivery & WithId)[],
    options: Bounds & CurrentDate = {}
  ) {
    if (!deliveries) {
      return [];
    }
    const currentDate = options.currentDate ?? new Date();
    const lowerBound = options.lowerBound ?? startOfDay(currentDate);
    const upperBound = options.upperBound ?? addYears(lowerBound, TEN_YEARS);
    return deliveries
      .flatMap((delivery) => {
        const lowerBoundWithLeadTime = DeliveryUtils.addLeadTime(
          currentDate,
          delivery.minimumLeadTimeHours
        );
        return PrivateDeliveryUtils.getDeliveryDates(
          delivery,
          lowerBound,
          upperBound
        )
          .filter((date) => {
            return isAfter(date, lowerBoundWithLeadTime);
          })
          .map<DeliveryOption & WithDeliveryId>((date) => ({
            deliveryId: delivery.id,
            date: date,
            minimumOrder: delivery.minimumOrder,
            deliveryFee: delivery.deliveryFee,
            freeDeliveryAbove: delivery.freeDeliveryAbove,
            minimumLeadTimeHours: delivery.minimumLeadTimeHours,
            maximumLeadTimeHours: delivery.maximumLeadTimeHours,
          }));
      })
      .sort((a, b) => a.date.getTime() - b.date.getTime());
  }

  /**
   * Get all open delivery options with id,
   * i.e. delivery options with id, open at currentDate
   * @param deliveries
   * @param options
   * @returns
   */
  export function getAllOpenDeliveryOptionsWithId(
    deliveries: (VendorDelivery & WithId)[],
    options: Bounds & CurrentDate = {}
  ) {
    if (!deliveries) {
      return [];
    }
    const currentDate = options.currentDate ?? new Date();
    const lowerBound = options.lowerBound ?? startOfDay(currentDate);
    const upperBound = options.upperBound ?? addYears(lowerBound, TEN_YEARS);
    return deliveries
      .flatMap((delivery) => {
        return PrivateDeliveryUtils.getOpenDeliveryDates(
          delivery,
          currentDate,
          lowerBound,
          upperBound,
          delivery?.minimumLeadTimeHours ?? 0,
          delivery?.maximumLeadTimeHours ?? MAX_HOURS
        ).map<DeliveryOption & WithDeliveryId>((date) => ({
          deliveryId: delivery.id,
          date: date,
          minimumOrder: delivery.minimumOrder,
          deliveryFee: delivery.deliveryFee,
          freeDeliveryAbove: delivery.freeDeliveryAbove,
          minimumLeadTimeHours: delivery.minimumLeadTimeHours,
          maximumLeadTimeHours: delivery.maximumLeadTimeHours,
        }));
      })
      .sort((a, b) => a.date.getTime() - b.date.getTime());
  }

  /**
   * Returns true if the delivery has upcoming delivery dates,
   * i.e. delivery dates with a maximum lead time, not yet open
   * @param deliveries
   * @param options
   * @returns
   */
  export function hasUpcomingDeliveryDates(
    deliveries: VendorDelivery[],
    options: Bounds & CurrentDate = {}
  ): boolean {
    return !!DeliveryUtils.getNextUpcomingDeliveryOption(deliveries, options);
  }

  /**
   * Returns true if the delivery has next delivery dates,
   * i.e. delivery dates, open or not, at currentDate
   * @param deliveries
   * @param options
   * @returns
   */
  export function hasNextDeliveryDates(
    deliveries: VendorDelivery[],
    options: Bounds & CurrentDate = {}
  ): boolean {
    return !!DeliveryUtils.getNextDeliveryOption(deliveries, options);
  }
}

export namespace PrivateDeliveryUtils {
  /**
   * Get all open delivery dates at currentDate between lowerBound and upperBound,
   * taking into account the minimum and maximum lead times.
   * @param {Pick<VendorDelivery, 'expeditionTime' | 'deliveryDates' | 'deliverySchedule'>} delivery
   * @param {Date} currentDate
   * @param {Date} lowerBound
   * @param {Date} upperBound
   * @param {number} minimumleadTimeHours
   * @param {number} maximumLeadTimeHours
   * @returns {Date[]}
   */
  export function getOpenDeliveryDates(
    delivery: Pick<
      VendorDelivery,
      'expeditionTime' | 'deliveryDates' | 'deliverySchedule'
    >,
    currentDate: Date,
    lowerBound: Date,
    upperBound: Date,
    minimumLeadTimeHours: number,
    maximumLeadTimeHours: number
  ): Date[] {
    if (
      !delivery ||
      !currentDate ||
      !lowerBound ||
      !upperBound ||
      LangUtils.nullOrUndefined(minimumLeadTimeHours) ||
      minimumLeadTimeHours < 0 ||
      LangUtils.nullOrUndefined(maximumLeadTimeHours) ||
      maximumLeadTimeHours <= 0 ||
      minimumLeadTimeHours >= maximumLeadTimeHours
    )
      return [];

    const openingDate = DeliveryUtils.addLeadTime(
      currentDate,
      minimumLeadTimeHours
    );
    const closingDate = DeliveryUtils.addLeadTime(
      currentDate,
      maximumLeadTimeHours
    );
    return PrivateDeliveryUtils.getDeliveryDates(
      delivery,
      lowerBound,
      upperBound
    ).filter(
      (date) =>
        (isAfter(date, openingDate) || isEqual(date, openingDate)) &&
        (isBefore(date, closingDate) || isEqual(date, closingDate))
    );
  }

  /**
   * Get all delivery dates between lowerBound and upperBound,
   * with the expedition time,
   * not taking into account the minimum and maximum lead times,
   * @param {Pick<VendorDelivery, 'expeditionTime' | 'deliveryDates' | 'deliverySchedule'>} delivery
   * @param {Date} lowerBound
   * @param {Date} upperBound
   * @returns {Date[]}
   */
  export function getDeliveryDates(
    delivery: Pick<
      VendorDelivery,
      'expeditionTime' | 'deliveryDates' | 'deliverySchedule'
    >,
    lowerBound: Date,
    upperBound: Date
  ): Date[] {
    if (!delivery || !lowerBound || !upperBound) return [];

    lowerBound = startOfDay(lowerBound);
    upperBound = endOfDay(upperBound);
    return DeliveryUtils.getAllDeliveryDates(delivery, {
      lowerBound,
      upperBound,
    }).filter(
      (date) =>
        (isAfter(date, lowerBound) || isEqual(date, lowerBound)) &&
        (isBefore(date, upperBound) || isEqual(date, upperBound))
    );
  }
}
