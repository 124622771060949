import { endOfDay, isWithinInterval, max, min, startOfDay } from 'date-fns';
import { firstValueFrom, Observable } from 'rxjs';
import { DateRangeFacade } from 'src/app/util/date-range/date-range-facade';

import { Params } from '@angular/router';

import { map, take, takeUntil, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { AnalyticsFacade } from '@arrivage-analytics/api/analytics.facade';
import { TransactionDocumentsMetrics } from '@arrivage-components/metric/metrics.model';
import {
  ScheduledDelivery,
  ScheduledDeliveryDashboardMetrics,
} from '@arrivage-scheduled-deliveries/common/model/scheduled-deliveries.model';
import { EntitiesFacade } from '@arrivage-store/api/entities.facade';
import { FirestoreEntityActions } from '@arrivage-store/generators';
import { selectRouteParams } from '@arrivage-store/routing/router-state.selectors';
import { State } from '@arrivage-store/state';
import {
  Message,
  PurchaseOrder,
  PurchaseOrderState,
  WithId,
} from '@arrivage/model/dist/src/model';

import { PickupUtils } from '@arrivage-distribution/vendor/utils/pickup.utils';
import { ContextFacade } from '@arrivage-store/api/context.facade';
import { Dictionary } from 'lodash';
import { BasePurchaseOrderService } from '../services/base-purchase-order.service';
import { PurchaseOrderActions } from '../store/base-purchase-orders.actions';
import { PurchaseOrdersSelectors } from '../store/base-purchase-orders.selectors';
import { PurchaseOrdersByDateRangeLoadingCollectionState } from '../store/base-purchase-orders.state';
import { PurchaseOrderApiService } from './purchase-orders.api.service';

export class PurchaseOrdersFacade<
    T extends BasePurchaseOrderService = BasePurchaseOrderService,
  >
  extends EntitiesFacade<PurchaseOrder, State>
  implements DateRangeFacade<PurchaseOrder>
{
  readonly purchaseOrderState = PurchaseOrderState;

  loadedByDateRange$: Observable<(PurchaseOrder & WithId)[]>; // unused
  loadedBySentDateRange$: Observable<(PurchaseOrder & WithId)[]>;
  loadedByDeliveryOrPickupDateRange$: Observable<(PurchaseOrder & WithId)[]>;
  loadedDeliveredBySentDateRange$: Observable<(PurchaseOrder & WithId)[]>;
  loadedDeliveredByDeliveryOrPickupDateRange$: Observable<
    (PurchaseOrder & WithId)[]
  >;
  isLoadingByDateRange$: Observable<boolean>;
  dateRange$: Observable<Interval>;
  byDateRangeState$: Observable<PurchaseOrdersByDateRangeLoadingCollectionState>;

  private unpaidMetrics$: Observable<TransactionDocumentsMetrics>;
  private undeliveredMetrics$: Observable<TransactionDocumentsMetrics>;
  private submittedMetrics$: Observable<TransactionDocumentsMetrics>;
  private scheduledDeliveryMetrics$: Observable<ScheduledDeliveryDashboardMetrics>;

  constructor(
    store: Store<State>,
    /**
     * Those two properties need to be overriden in the child class
     * instead of being just parameters of the constructor to ensure
     * that the child class has access to the correct selectors and actions.
     *
     * This can be changed by adding new generic parameters to the parent class.
     */
    protected override selectors: PurchaseOrdersSelectors,
    protected override actions: PurchaseOrderActions &
      FirestoreEntityActions<PurchaseOrder>,
    protected purchaseOrderApiService: PurchaseOrderApiService,
    protected analytics: AnalyticsFacade,
    protected purchaseOrderService: T,
    protected contextFacade: ContextFacade
  ) {
    super(store, actions, selectors);

    this.loadedBySentDateRange$ = this.store.select(
      selectors.selectLoadedBySentDateRange
    );
    this.loadedByDeliveryOrPickupDateRange$ = this.store.select(
      selectors.selectLoadedByDeliveryOrPickupDateRange
    );

    this.loadedDeliveredBySentDateRange$ = this.store.select(
      selectors.selectLoadedDeliveredBySentDateRange
    );
    this.loadedDeliveredByDeliveryOrPickupDateRange$ = this.store.select(
      selectors.selectLoadedDeliveredByDeliveryOrPickupDateRange
    );

    this.isLoadingByDateRange$ = this.store.select(
      selectors.selectIsLoadingByDateRange
    );
    this.byDateRangeState$ = this.store.select(
      selectors.selectByDateRangeState
    );
    this.dateRange$ = this.store.select(selectors.selectCurrentDateRange);
    this.unpaidMetrics$ = this.store.select(selectors.selectUnpaidMetrics);
    this.undeliveredMetrics$ = this.store.select(
      selectors.selectNotDeliveredMetrics
    );
    this.submittedMetrics$ = this.store.select(
      selectors.selectSubmittedMetrics
    );
    this.scheduledDeliveryMetrics$ = this.store.select(
      selectors.selectScheduledDeliveryMetrics
    );
  }

  getDateRange(): Observable<Interval> {
    return this.dateRange$;
  }

  getLoadedByDateRange(): Observable<(PurchaseOrder & WithId)[]> {
    // unused
    return this.loadedByDateRange$;
  }

  getLoadedBySentDateRange(): Observable<(PurchaseOrder & WithId)[]> {
    return this.loadedBySentDateRange$;
  }

  getLoadedByDeliveryOrPickupDateRange(): Observable<
    (PurchaseOrder & WithId)[]
  > {
    return this.loadedByDeliveryOrPickupDateRange$;
  }

  /**
   * Same as `getLoadedBySentDateRange` but filters delivered and confirmed/completed purchase orders
   * @returns
   */
  getLoadedDeliveredBySentDateRange(): Observable<(PurchaseOrder & WithId)[]> {
    return this.loadedDeliveredBySentDateRange$;
  }

  /**
   * Same as `getLoadedByDeliveryOrPickupDateRange` but filters delivered and confirmed/completed purchase orders
   * @returns
   */
  getLoadedDeliveredByDeliveryOrPickupDateRange(): Observable<
    (PurchaseOrder & WithId)[]
  > {
    return this.loadedDeliveredByDeliveryOrPickupDateRange$;
  }

  getIsLoadingByDateRange(): Observable<boolean> {
    return this.isLoadingByDateRange$;
  }

  getByDateRangeState(): Observable<PurchaseOrdersByDateRangeLoadingCollectionState> {
    return this.byDateRangeState$;
  }

  loadByDateRange(interval: Interval): void {
    this.store.dispatch(
      this.actions.queryByDateRangeGuard({
        newDateRange: interval,
      })
    );
  }

  override addItem(item: PurchaseOrder): Promise<string> {
    throw new Error('Unsupported, use one of the createPurchaseOrder methods');
  }

  async createPurchaseOrder(
    purchaseOrder: PurchaseOrder,
    message?: Message,
    sendEmailToMyself?: boolean,
    pdfBase64?: string
  ): Promise<string> {
    const createPurchaseOrder$ =
      await this.purchaseOrderApiService.createPurchaseOrder(
        purchaseOrder,
        message,
        sendEmailToMyself,
        pdfBase64
      );

    return createPurchaseOrder$
      .pipe(
        take(1),
        tap(() => this.analytics.logCreatePurchaseOrder(purchaseOrder))
      )
      .toPromise();
  }

  async updatePurchaseOrder(
    purchaseOrder: PurchaseOrder & WithId
  ): Promise<string> {
    const modifiedByASeller = await firstValueFrom(
      this.contextFacade.getOrganizationId().pipe(
        map((organizationId) => {
          return organizationId === purchaseOrder.vendor.organizationId;
        })
      )
    );

    const updatePurchaseOrder$ =
      await this.purchaseOrderApiService.updatePurchaseOrder(
        purchaseOrder,
        modifiedByASeller
      );

    return updatePurchaseOrder$.pipe(take(1)).toPromise();
  }

  getScheduledDeliveryMetrics(): Observable<ScheduledDeliveryDashboardMetrics> {
    return this.scheduledDeliveryMetrics$;
  }

  verifyPurchaseOrder(verificationId: string): Promise<string> {
    return this.purchaseOrderApiService
      .verifyPurchaseOrder(verificationId)
      .pipe(take(1))
      .toPromise();
  }

  getByRelationshipId(
    relationshipId: string
  ): Observable<(PurchaseOrder & WithId)[]> {
    return this.store.select(
      this.selectors.selectByRelationshipId(relationshipId)
    );
  }

  getUnpaidMetrics() {
    return this.unpaidMetrics$;
  }

  getSubmittedMetrics() {
    return this.submittedMetrics$;
  }

  getNotDeliveredMetrics() {
    return this.undeliveredMetrics$;
  }

  getDateRangeCoveredByScheduledDeliveriesToDeliver(): Observable<Interval> {
    return this.store.select(
      this.selectors.selectDateRangeCoveredByScheduledDeliveriesToDeliver
    );
  }

  loadScheduledDeliveriesByDateRange(
    interval: Interval,
    unsubscribe$: Observable<void>
  ): void {
    this.getDateRangeCoveredByScheduledDeliveriesToDeliver()
      .pipe(
        takeUntil(unsubscribe$),
        map((dateRange) => {
          if (!dateRange.start || !dateRange.end) {
            return interval;
          }
          return {
            start: startOfDay(min([dateRange.start, interval.start])),
            end: endOfDay(max([dateRange.end, interval.end])),
          };
        })
      )
      .subscribe((dateRange) => this.loadByDateRange(dateRange));
  }

  getScheduledDeliveriesToDeliver() {
    return this.store.select(this.selectors.selectScheduledDeliveriesToDeliver);
  }

  getScheduledDeliveriesDeliveredForSelectedDateRange(
    selectedDateRange: Interval
  ) {
    return this.store
      .select(this.selectors.selectScheduledDeliveriesDelivered)
      .pipe(
        map((deliveries) => {
          return deliveries.filter((delivery) => {
            return (
              isWithinInterval(delivery.deliveryDate, selectedDateRange) ||
              isWithinInterval(delivery.pickupDate, selectedDateRange)
            );
          });
        })
      );
  }

  getRouteParams(): Observable<Params> {
    return this.store.select(selectRouteParams);
  }

  getScheduledDelivery(): Observable<ScheduledDelivery | null> {
    return this.store.select(this.selectors.selectScheduledDelivery);
  }

  /**
   * Get the purchaseOrders that are in status submitted or modified
   * for the current date range
   */
  getPurchaseOrdersNotApproved(): Observable<(PurchaseOrder & WithId)[]> {
    return this.store.select(this.selectors.selectPurchaseOrdersNotApproved);
  }

  /**
   * Get the purchaseOrders that are in status submitted or modified
   * for the current date range grouped by delivery id and delivery date
   */
  getPurchaseOrdersNotApprovedByDeliveryIdByDate(): Observable<
    Dictionary<Dictionary<(PurchaseOrder & WithId)[]>>
  > {
    return this.store
      .select(this.selectors.selectPurchaseOrdersNotApprovedByDateRange)
      .pipe(
        map((purchaseOrders) => {
          return purchaseOrders.reduce(
            (acc, purchaseOrder) => {
              if (purchaseOrder.delivery) {
                return this.buildPurchaseOrderDictionary(
                  acc,
                  purchaseOrder.delivery.id,
                  purchaseOrder.deliveryDate,
                  purchaseOrder
                );
              }
              return acc;
            },
            {} as Dictionary<Dictionary<(PurchaseOrder & WithId)[]>>
          );
        })
      );
  }

  /**
   * Get the purchaseOrders that are in status submitted or modified
   * for the current date range grouped by pickup id and pickup date
   */
  getPurchaseOrdersNotApprovedByPickupIdByDate(): Observable<
    Dictionary<Dictionary<(PurchaseOrder & WithId)[]>>
  > {
    return this.store
      .select(this.selectors.selectPurchaseOrdersNotApprovedByDateRange)
      .pipe(
        map((purchaseOrders) => {
          return purchaseOrders.reduce(
            (acc, purchaseOrder) => {
              if (purchaseOrder.pickup) {
                return this.buildPurchaseOrderDictionary(
                  acc,
                  purchaseOrder.pickup.id,
                  PickupUtils.getPickupOptionDate(purchaseOrder.pickup, 'to'),
                  purchaseOrder
                );
              }
              return acc;
            },
            {} as Dictionary<Dictionary<(PurchaseOrder & WithId)[]>>
          );
        })
      );
  }

  getLastPurchaseOrderForRelationshipId(
    relationshipId: string
  ): Observable<PurchaseOrder & WithId> {
    return this.purchaseOrderService.getLastConfirmedPurchaseOrderForRelationId(
      relationshipId
    );
  }

  /**
   * Build a dictionary of purchase orders grouped by id and date
   * @param acc
   * @param id
   * @param date
   * @param purchaseOrder
   * @returns
   */
  private buildPurchaseOrderDictionary(
    acc: Dictionary<Dictionary<(PurchaseOrder & WithId)[]>>,
    id: string,
    date: Date,
    purchaseOrder: PurchaseOrder & WithId
  ) {
    if (!acc[id]) {
      acc[id] = {};
    }

    if (!acc[id][date.getTime()]) {
      acc[id][date.getTime()] = [];
    }

    acc[id][date.getTime()].push(purchaseOrder);

    return acc;
  }
}
