import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import firebase from 'firebase/compat';
import _ from 'lodash';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { map, take, takeUntil } from 'rxjs/operators';

import { AddressUtils } from '@arrivage-util/address.utils';
import {
  Address,
  DeliveryZoneDefinition,
} from '@arrivage/model/dist/src/model';

@Injectable({
  providedIn: 'root',
})
export class DeliveryZonesService implements OnDestroy {
  private _loading = false;
  private _loaded$ = new BehaviorSubject<boolean>(false);
  private _deliveryZoneData$ = new BehaviorSubject<DeliveryZoneDefinition[]>(
    []
  );

  private clientSubscription: Subscription;

  private subRegionInRegionDictionary: { [key: string]: string[] } = {};
  private deliveryZoneData$ = this._deliveryZoneData$.asObservable();
  private deliveryZoneDataByCode$ = this.deliveryZoneData$.pipe(
    map((d) => _.keyBy(d, 'code'))
  );
  private loaded$ = this._loaded$.asObservable();
  private destroyed$: Subject<void> = new Subject();

  constructor(private httpClient: HttpClient) {}

  ngOnDestroy(): void {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
    }
    this.destroyed$.next();
  }

  loadZoneData() {
    if (!this._loaded$.value && !this._loading) {
      this._loading = true;
      this.clientSubscription = this.httpClient
        .get('assets/map/qc_zones_data.json')
        .pipe(take(1))
        .subscribe((data) => {
          this._loading = false;
          this._deliveryZoneData$.next(data as DeliveryZoneDefinition[]);
          this.fillDictionary();
          this._loaded$.next(true);
        });
    }
  }

  isLoaded() {
    return this.loaded$;
  }

  getDeliveryZoneData() {
    return this.deliveryZoneData$;
  }

  getDeliveryZoneDataByCode() {
    return this.deliveryZoneDataByCode$;
  }

  getSubRegionInRegionDictionary() {
    return this.subRegionInRegionDictionary;
  }

  getSubRegionInRegionList(regionList: string[]) {
    return _.flatten(
      regionList.map((region) => {
        return this.subRegionInRegionDictionary[region];
      })
    );
  }

  getRegionFromSubRegion(subRegion: string) {
    const regionList = Object.keys(this.subRegionInRegionDictionary);
    for (const region of regionList) {
      if (this.subRegionInRegionDictionary[region].includes(subRegion)) {
        return region;
      }
    }
  }

  private fillDictionary() {
    // generate a dictionary of sub-regions in each region
    this.deliveryZoneData$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        data
          .filter((d) => d.type === 'region')
          .forEach((region) => {
            // check all data to find sub-regions in this region
            // for Laval and Montreal, we want to include municipalities and boroughs
            // as they are also considered region and sub-regions
            const subRegions = data.filter((d) =>
              region.name === 'Laval' || region.name === 'Montréal'
                ? (d.type === 'municipality' || d.type === 'borough') &&
                  region.name !== d.name
                : d.type === 'sub_region'
            );
            // filter out sub-regions that are not in this region
            const subRegionsInRegion = subRegions.filter((d) => {
              return booleanPointInPolygon(d.center, region.definition);
            });
            // add sub-regions to dictionary
            this.subRegionInRegionDictionary[region.name] =
              subRegionsInRegion.map((d) => d.name);
          });
      });
  }

  /**
   * Utility method to get the delivery zone definition for a given lat/long
   *
   * !WARNING: Make sure the data is loaded first otherwise this will return an empty array.
   *
   * @param point the lat/long for which to find zone definitions
   * @returns the zone definitions for the given lat/long
   */
  findZonesFor(point: firebase.firestore.GeoPoint): DeliveryZoneDefinition[] {
    return _.filter(this._deliveryZoneData$.value, (dz) =>
      booleanPointInPolygon([point?.longitude, point?.latitude], dz.definition)
    );
  }

  async isAddressInSomeDeliveryZone(
    address: Address,
    deliveryZoneCodes: string[]
  ) {
    const geoPoint = await AddressUtils.getGeoPointFromAddress(address);
    const deliveryZoneDefinitions = this.findZonesFor(geoPoint);
    return deliveryZoneDefinitions.some((zone) =>
      deliveryZoneCodes.includes(zone.code)
    );
  }
}
