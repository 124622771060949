import { of } from 'rxjs';

import { Injectable } from '@angular/core';

import { switchMap, map, catchError, tap } from 'rxjs/operators';

import { Actions, ofType, createEffect } from '@ngrx/effects';

import { RelationsLogic } from '@arrivage-relationship/common/model/relations-logic.model';
import { reportError } from '@arrivage-sentry/report-error';
import { RelationshipInfosService } from '@arrivage-services/relationship-infos/relationship-infos.service';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { ContextFeedback } from '@arrivage-store/context/context.effect';

import {
  getExternalCustomerInfoFailure,
  getExternalCustomerInfoSuccess,
  getExternalCustomerInfo,
} from './external-store.actions';

interface ExternalCustomerInfoFeedback {
  external_customer_has_account: string;
  external_customer_doesnt_exist: string;
  external_customer_loading_failed: string;
}

export const ExternalCustomerInfoFeedback: ExternalCustomerInfoFeedback = {
  external_customer_has_account: 'external_customer_has_account',
  external_customer_doesnt_exist: 'external_customer_doesnt_exist',
  external_customer_loading_failed: 'external_customer_loading_failed',
};

@Injectable()
export class ExternalCustomerInfoEffects {
  constructor(
    private actions$: Actions,
    private relationshipInfosService: RelationshipInfosService,
    private snackbarService: SnackbarService
  ) {}

  getExternalCustomerInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getExternalCustomerInfo),
      switchMap((action) =>
        this.relationshipInfosService
          .getRelationshipInfoByRelationshipId(
            action.relationshipId,
            action.vendorOrganizationId
          )
          .pipe(
            map((relationshipInfo) => {
              if (relationshipInfo) {
                return RelationsLogic.extractOrganizationSummaryFromCustomer(
                  relationshipInfo
                );
              }
              return null;
            }),
            map((customerInfo) =>
              getExternalCustomerInfoSuccess({
                customerInfo: customerInfo,
              })
            ),
            catchError((e) => {
              reportError(e);
              return of(
                getExternalCustomerInfoFailure({
                  error: ContextFeedback.loading_failed,
                })
              );
            })
          )
      )
    )
  );

  getExternalCustomerInfoFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getExternalCustomerInfoFailure),
      tap((feedback) => this.snackbarService.showError(feedback.error))
    )
  );
}
