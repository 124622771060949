import { isWithinInterval } from 'date-fns';
import _ from 'lodash';
import { combineLatest, from, Observable } from 'rxjs';

import {
  collection,
  Firestore,
  getDocs,
  limit,
  orderBy,
  query,
  QueryConstraint,
  where,
} from '@angular/fire/firestore';

import { map } from 'rxjs/operators';

import { BaseService, PathSpec } from '@arrivage-services/base.service';
import {
  EntityChangeAction,
  OrganizationEntityService,
} from '@arrivage-services/organization-entity.service';
import { DateUtils } from '@arrivage-util/date.utils';
import {
  LocalDate,
  PurchaseOrder,
  PurchaseOrderState,
  PurchaseOrderStatus,
  WithId,
} from '@arrivage/model/dist/src/model';
import { CollectionNames } from '@arrivage/model/dist/src/utils';

import { GeoPointUtils } from '@arrivage-util/geopoint.utils';
import { PurchaseOrdersByDateType } from '../store/base-purchase-orders.state';
import { TransactionDocumentIdFields } from './purchase-orders-id-fields';

export class BasePurchaseOrderService
  extends BaseService<PurchaseOrder>
  implements OrganizationEntityService<PurchaseOrder>
{
  static readonly PURCHASE_ORDERS_COLLECTION =
    CollectionNames.PURCHASE_ORDER_COLLECTION;
  static convertFields(firebasePurchaseOrder: any): PurchaseOrder {
    if (!firebasePurchaseOrder) {
      return undefined;
    }

    const purchaseOrder: PurchaseOrder & WithId = {
      ...firebasePurchaseOrder,
    };
    if (firebasePurchaseOrder.sentOn) {
      purchaseOrder.sentOn = DateUtils.toDate(firebasePurchaseOrder.sentOn);
    }
    if (firebasePurchaseOrder.deliveryDate) {
      purchaseOrder.deliveryDate = DateUtils.toDate(
        firebasePurchaseOrder.deliveryDate
      );
    }
    if (firebasePurchaseOrder.pickup?.location) {
      purchaseOrder.pickup.location = GeoPointUtils.toGeoPoint(
        firebasePurchaseOrder.pickup.location
      );
    }
    return purchaseOrder;
  }

  constructor(
    firestore: Firestore,
    protected organizationIdField: TransactionDocumentIdFields.ID_FIELDS
  ) {
    super(firestore);
  }

  create(
    organizationId: string,
    purchaseOrder: PurchaseOrder
  ): Promise<string> {
    return this._create(this.pathSpec(), purchaseOrder);
  }

  get(
    organizationId: string,
    purchaseOrderId: string
  ): Observable<PurchaseOrder & WithId> {
    return this._get(
      this.pathSpec(),
      purchaseOrderId,
      BasePurchaseOrderService.convertFields
    );
  }

  getLastConfirmedPurchaseOrderForRelationId(
    relationshipId: string
  ): Observable<PurchaseOrder & WithId> {
    return from(
      getDocs(
        query(
          collection(this.firestore, 'purchaseOrders'),
          where('relationshipId', '==', relationshipId),
          where('status', 'in', [
            PurchaseOrderStatus.CONFIRMED,
            PurchaseOrderStatus.COMPLETED,
          ]),
          orderBy('sentOn', 'desc'),
          limit(1)
        )
      )
    ).pipe(
      map((snapshot) => {
        if (snapshot.empty) {
          return null;
        }
        return {
          ...BasePurchaseOrderService.convertFields(snapshot.docs[0].data()),
          id: snapshot.docs[0].id,
        } as PurchaseOrder & WithId;
      })
    );
  }

  set(
    organizationId: string,
    recordId: string,
    record: PurchaseOrder
  ): Promise<void> {
    return this._set(this.pathSpec(), recordId, record);
  }

  update(
    organizationId: string,
    purchaseOrderId: string,
    purchaseOrder: Partial<PurchaseOrder>
  ): Promise<void> {
    return this._update(this.pathSpec(), purchaseOrderId, purchaseOrder);
  }

  connect(
    organizationId: string
  ): Observable<EntityChangeAction<PurchaseOrder>[]> {
    const queryConstraints = [
      where(this.organizationIdField, '==', organizationId),
      where('state', '==', PurchaseOrderState.OPEN),
      where('status', 'in', [
        PurchaseOrderStatus.SUBMITTED,
        PurchaseOrderStatus.MODIFIEDBYVENDOR,
        PurchaseOrderStatus.MODIFIEDBYCUSTOMER,
        PurchaseOrderStatus.CONFIRMED,
        PurchaseOrderStatus.COMPLETED,
      ]),
      orderBy('sentOn', 'desc'),
    ];

    return this._connect(
      this.pathSpec(),
      BasePurchaseOrderService.convertFields,
      null,
      ...queryConstraints
    );
  }

  list(
    organizationId: string,
    dateRange?: Interval,
    state?: PurchaseOrderState,
    statuses?: PurchaseOrderStatus[]
  ): Observable<PurchaseOrdersByDateType> {
    const importantQueryConstraints: QueryConstraint[] = [
      where(this.organizationIdField, '==', organizationId),
    ];

    const queryConstraints = [];
    if (state) {
      queryConstraints.push(where('state', '==', state));
    }
    if (statuses && statuses.length > 0) {
      queryConstraints.push(where('status', 'in', statuses));
    }

    const queryConstraintsByDateType: QueryConstraint[][] = [];

    const startDate = new Date(dateRange.start);
    const endDate = new Date(dateRange.end);

    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();

    queryConstraintsByDateType.push([
      ...importantQueryConstraints,
      where('deliveryDate', '>=', startDate),
      where('deliveryDate', '<=', endDate),
      ...queryConstraints,
      orderBy('deliveryDate', 'desc'),
    ]);

    queryConstraintsByDateType.push([
      ...importantQueryConstraints,
      where('pickup.selectedDay.year', '>=', startYear),
      where('pickup.selectedDay.year', '<=', endYear),
      ...queryConstraints,
      orderBy('pickup.selectedDay.year', 'desc'),
    ]);

    queryConstraintsByDateType.push([
      ...importantQueryConstraints,
      where('sentOn', '>=', startDate),
      where('sentOn', '<=', endDate),
      ...queryConstraints,
      orderBy('sentOn', 'desc'),
    ]);
    return combineLatest(
      _.map(queryConstraintsByDateType, (queryConstraints) =>
        this._list(
          this.pathSpec(),
          BasePurchaseOrderService.convertFields,
          ...queryConstraints
        )
      )
    ).pipe(
      map(([delivery, pickup, sent]) => {
        return {
          sent: sent,
          deliveryOrPickup: this.combineDeliveryAndPickup(
            delivery,
            pickup,
            dateRange
          ),
        };
      })
    );
  }

  remove(organizationId: string, PurchaseOrderId: string): Promise<void> {
    return this._delete(this.pathSpec(), PurchaseOrderId);
  }

  private combineDeliveryAndPickup(
    delivery: (PurchaseOrder & WithId)[],
    pickup: (PurchaseOrder & WithId)[],
    dateRange: Interval
  ): (PurchaseOrder & WithId)[] {
    return _([delivery, pickup])
      .flatten()
      .uniqBy(
        (purchaseOrder: (PurchaseOrder & WithId) & WithId) => purchaseOrder.id
      )
      .filter(
        (purchaseOrder) =>
          !purchaseOrder.pickup ||
          isWithinInterval(
            LocalDate.toDate(purchaseOrder.pickup.selectedDay),
            dateRange
          )
      )
      .sortBy((purchaseOrder: (PurchaseOrder & WithId) & WithId) => {
        if (purchaseOrder.deliveryDate) {
          return purchaseOrder.deliveryDate;
        }
        if (purchaseOrder.pickup) {
          return new Date(
            purchaseOrder.pickup.selectedDay.year,
            purchaseOrder.pickup.selectedDay.month - 1,
            purchaseOrder.pickup.selectedDay.day
          );
        }
        return purchaseOrder.sentOn;
      })
      .value();
  }

  private pathSpec(): PathSpec[] {
    return [
      {
        collection: BasePurchaseOrderService.PURCHASE_ORDERS_COLLECTION,
      },
    ];
  }
}
