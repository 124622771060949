import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
  OfferItem,
  OrderItem,
  Product,
  WithId,
} from '@arrivage/model/dist/src/model';

@Component({
  selector: 'app-product-info-dialog',
  templateUrl: './product-info-dialog.component.html',
  styleUrls: ['./product-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductInfoDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      product: Product & WithId;
      offerItems: OfferItem[];
      isExtraSmallScreen: boolean;
      showPriceAndStock: boolean;
      orderItem: OrderItem;
    }
  ) {}
}
