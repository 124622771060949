import { Observable } from 'rxjs';

import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  fadeInOut,
  openCloseAnimation,
} from '@arrivage-animations/open-close.animations';
import { ScreenSizeService } from '@arrivage-services/screen-size.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [openCloseAnimation, fadeInOut],
})
export class HeaderComponent implements OnInit {
  isSmallOrMediumScreen$: Observable<boolean>;

  @Input()
  allowBack = true;

  @Input()
  title: string;

  @Input()
  helpBox = false;

  @Input()
  helpBoxDisplayed = false;

  @Input()
  logo: string;

  @Output()
  showHelpBox = new EventEmitter<'hide' | 'show'>();

  constructor(
    private location: Location,
    private screenSizeService: ScreenSizeService
  ) {}

  get openCloseState(): 'open' | 'close' {
    if (this.helpBox) {
      return this.helpBoxDisplayed ? 'open' : 'close';
    }

    return 'open';
  }

  get fadeInOutState(): 'open' | 'close' {
    if (this.helpBox) {
      return this.helpBoxDisplayed ? 'close' : 'open';
    }

    return 'close';
  }

  ngOnInit(): void {
    this.isSmallOrMediumScreen$ =
      this.screenSizeService.getIsScreenMediumOrSmall();
  }

  onShowHelpBox() {
    this.helpBoxDisplayed
      ? this.showHelpBox.emit('hide')
      : this.showHelpBox.emit('show');
  }

  back() {
    this.location.back();
  }
}
