import { ActionCreator, createAction, props } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

import {
  FirestoreEntityActions,
  generateActions,
} from '@arrivage-store/generators';
import { PurchaseOrder } from '@arrivage/model/dist/src/model';

import { PurchaseOrdersByDateType } from './base-purchase-orders.state';

export interface PurchaseOrderActions
  extends FirestoreEntityActions<PurchaseOrder> {
  queryByDateRangeGuard: ActionCreator<
    string,
    (props: { newDateRange: Interval }) => {
      newDateRange: Interval;
    } & TypedAction<string>
  >;
  queryByDateRange: ActionCreator<
    string,
    (props: {
      newDateRange: Interval;
      newDateRangesToStore: Interval[];
      newDateRangesToQuery: Interval[];
    }) => {
      newDateRange: Interval;
      newDateRangesToStore: Interval[];
      newDateRangesToQuery: Interval[];
    } & TypedAction<string>
  >;
  queryByDateRangeSuccess: ActionCreator<
    string,
    (props: {
      items: PurchaseOrdersByDateType;
      newDateRange: Interval;
      newDateRangesToStore: Interval[];
    }) => {
      items: PurchaseOrdersByDateType;
      newDateRange: Interval;
      newDateRangesToStore: Interval[];
    } & TypedAction<string>
  >;
  queryByDateRangeFailure: ActionCreator<
    string,
    (props: { error: string }) => { error: string } & TypedAction<string>
  >;
}
export function generatePurchaseOrderActions(
  featureName: string
): PurchaseOrderActions {
  return {
    ...generateActions(featureName),
    queryByDateRangeGuard: createAction(
      `[${featureName}] Query By date range Guard`,
      props<{
        newDateRange: Interval;
        newDateRangesToStore: Interval[];
        newDateRangesToQuery: Interval[];
      }>()
    ),
    queryByDateRange: createAction(
      `[${featureName}] Query By date range`,
      props<{
        newDateRange: Interval;
        newDateRangesToStore: Interval[];
        newDateRangesToQuery: Interval[];
      }>()
    ),
    queryByDateRangeSuccess: createAction(
      `[${featureName}] Query By date range success`,
      props<{
        items: PurchaseOrdersByDateType;
        newDateRange: Interval;
        newDateRangesToStore: Interval[];
      }>()
    ),
    queryByDateRangeFailure: createAction(
      `[${featureName}] Query By date range failure `,
      props<{ error: string }>()
    ),
  };
}
