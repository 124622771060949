import { getDate, getMonth } from 'date-fns';
import _ from 'lodash';

import { Money } from '@arrivage/model/dist/src/model';

export namespace ProductUtils {
  export function getReducedPricePercentage(
    basePrice: Money,
    reducedPrice: Money
  ): number {
    if (basePrice && reducedPrice && !_.isEqual(basePrice, reducedPrice)) {
      // Using Money methods because typescript doesn't handle decimal operations well
      const reduction = Money.substract(basePrice, reducedPrice);
      const reductionPercentage = Money.divide(
        Money.multiply(reduction, 100),
        Money.display(basePrice).amount
      );
      return Math.floor(Money.display(reductionPercentage).amount);
    }
    return null;
  }

  export function getMidMonthEnumFromDate(todaysDate: Date): number {
    const date = getDate(todaysDate);
    const month = getMonth(todaysDate) + 1;
    return date > 15 ? month * 2 : month * 2 - 1;
  }
}
