import { Injectable } from '@angular/core';
import {
  Analytics,
  getAnalytics,
  isSupported,
  logEvent,
  setUserProperties,
} from '@angular/fire/analytics';

import { tap, map } from 'rxjs/operators';

import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as authActions from '@arrivage-auth/store/auth.actions';

import * as actions from './analytics.actions';

@Injectable()
export class AnalyticsEffects {
  private analytics: Analytics;

  constructor(private actions$: Actions) {}

  logEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.logEvent),
        tap(async (event) => {
          if (await isSupported()) {
            this.analytics = getAnalytics();
            logEvent(this.analytics, event.eventName, event.params);
          }
        })
      ),
    { dispatch: false }
  );

  setOrganizationProperties$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.setOrganizationProperties),
        tap(async (action) => {
          if (await isSupported()) {
            this.analytics = getAnalytics();
            setUserProperties(this.analytics, {
              seller: action.organization.isSeller,
              buyer: action.organization.isBuyer,
              isSeller: action.organization.isSeller.toString(),
              isBuyer: action.organization.isBuyer.toString(),
            });
          }
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.Logout),
      map((a) => actions.logEvent({ eventName: 'logout' }))
    )
  );
}
