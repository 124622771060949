import { isBefore } from 'date-fns';
import _, { Dictionary } from 'lodash';
import { ICreatePDF } from 'pdfmake-wrapper';

import { MatDialog } from '@angular/material/dialog';

import { translate } from '@jsverse/transloco';

import { LoadingFeedback } from '@arrivage-components/loading-dialog/loading-feedback.model';
import { CustomerAccess } from '@arrivage-customer-access/customer-access.model';
import { DeliveryUtils } from '@arrivage-distribution/common/utils/delivery.utils';
import { PickupUtils } from '@arrivage-distribution/vendor/utils/pickup.utils';
import {
  ShareAllOffersDialogComponent,
  ShareAllOffersDialogData,
  ShareAllOffersDialogResponse,
} from '@arrivage-inventory/components/share-all-offers-dialog/share-all-offers-dialog.component';
import { InventoryLine } from '@arrivage-inventory/model/inventory.model';
import { OfferFeedback } from '@arrivage-offers/common/model/offer.model';
import { VendorOfferFacade } from '@arrivage-offers/vendor/api/vendor-offers.facade';
import {
  OfferExpirationDialogComponent,
  OfferExpirationDialogComponentData,
} from '@arrivage-offers/vendor/components/offer-expiration-dialog/offer-expiration-dialog.component';
import {
  MultiShareToCustomersDialogComponent,
  MultiShareToCustomersDialogData,
  MultiShareToCustomersDialogResponse,
} from '@arrivage-price-lists/components/multi-share-to-customers-dialog/multi-share-to-customers-dialog.component';
import {
  ShareToCustomersDialogComponent,
  ShareToCustomersDialogData,
  ShareToCustomersDialogResponse,
} from '@arrivage-price-lists/components/share-to-customers-dialog/share-to-customers-dialog.component';
import {
  DatesAvailability,
  PriceListItemLine,
} from '@arrivage-price-lists/model/price-list.model';
import { DeliveryByRelationshipId } from '@arrivage-relationship/common/model/relationship.model';
import { MainPageLoadService } from '@arrivage-services/main-page-load.service';
import { PdfService } from '@arrivage-services/pdf.service';
import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { DateUtils } from '@arrivage-util/date.utils';
import { LangUtils } from '@arrivage-util/lang.utils';
import {
  Delivery,
  Format,
  InventoryItem,
  Money,
  Offer,
  OfferItem,
  Organization,
  PaymentMethod,
  PriceListItem,
  Product,
  WithId,
} from '@arrivage/model/dist/src/model';

export namespace OfferUtils {
  export const UPDATED_TIME_WARNING_CAP = 7;

  export enum ShareOfferIssuesMessages {
    NOT_ACTIVE = 'priceLists.system-message.not-shareable.not-active',
    NO_CUSTOMER = 'priceLists.system-message.not-shareable.no-client',
    NO_PRODUCT = 'priceLists.system-message.not-shareable.no-product',
    NO_DELIVERY_OR_NO_PICKUP = 'priceLists.system-message.not-shareable.no-pickup-or-no-delivery',
  }

  export function lastOfferRefreshTime(offer: Offer): Date {
    return offer.lastSentTime
      ? new Date(Math.max(offer.lastSentTime, offer.lastUpdateTime.getTime()))
      : offer.lastUpdateTime;
  }

  export function isOfferActive(offer: Offer): boolean {
    if (offer) {
      return offer.isActive;
    }
    return false;
  }

  export function isOfferHasOfferItems(offer: Offer): boolean {
    if (offer) {
      return offer.offerItems.length > 0;
    }
    return false;
  }

  export function isOfferUpdateTimeIsOverWarningCap(offer: Offer): boolean {
    if (offer) {
      return (
        DateUtils.countDaysBetween(offer.lastUpdateTime) >
        UPDATED_TIME_WARNING_CAP
      );
    }
    return false;
  }

  export async function shareOffer(
    data: ShareToCustomersDialogData,
    dialog: MatDialog,
    mainPageLoadService: MainPageLoadService,
    offerFacade: VendorOfferFacade,
    pdfService: PdfService,
    snackbarService: SnackbarService,
    isSmallScreen: boolean
  ) {
    const dialogRef = dialog.open<
      ShareToCustomersDialogComponent,
      ShareToCustomersDialogData,
      ShareToCustomersDialogResponse
    >(ShareToCustomersDialogComponent, {
      panelClass: isSmallScreen ? 'full-dialog' : '',
      data: data,
      disableClose: true,
      width: '600px',
      maxHeight: '100vh',
    });

    dialogRef.componentInstance.openPdf.subscribe(async (outputResponse) => {
      try {
        pdfService.openPDF(
          await pdfService.generatePriceListPdf(
            data.priceListItems,
            data.offer.vendor,
            outputResponse.includeInactiveProducts
          )
        );
      } catch {
        snackbarService.showError(OfferFeedback.previewOfferPdf);
      }
    });

    dialogRef.afterClosed().subscribe(async (response) => {
      dialogRef.componentInstance.openPdf.unsubscribe();

      if (
        response &&
        (response?.customerIds.length > 0 || data.isTestWorkflow)
      ) {
        const action = data.isTestWorkflow
          ? OfferFeedback.shareOfferTest
          : OfferFeedback.shareOffer;
        mainPageLoadService.start(
          data.isTestWorkflow
            ? LoadingFeedback.ON_SHARING_OFFER_TEST
            : LoadingFeedback.ON_SHARING_OFFER
        );

        let pricelistPdf: ICreatePDF = null;

        if (response.attachPdf) {
          try {
            pricelistPdf = await pdfService.generatePriceListPdf(
              data.priceListItems,
              data.offer.vendor,
              response.includeInactiveProducts
            );
          } catch {
            snackbarService.showError(OfferFeedback.previewOfferPdf);
          }
        }

        offerFacade
          .shareOffer({
            offerId: data.offer.id,
            relationshipIds: response.customerIds.map((r) => r),
            message: response.message,
            pdfBase64:
              response.attachPdf && pricelistPdf
                ? await pdfService.getBase64(pricelistPdf)
                : null,
            isTestWorkflow: data.isTestWorkflow,
          })
          .then(() => {
            snackbarService.showSuccess(action);
          })
          .catch((e) => {
            snackbarService.showError(action);
          })
          .finally(() => mainPageLoadService.end());
      }
    });
  }

  export async function shareAllOffer(
    data: ShareAllOffersDialogData,
    dialog: MatDialog,
    mainPageLoadService: MainPageLoadService,
    offerFacade: VendorOfferFacade,
    pdfService: PdfService,
    snackbarService: SnackbarService,
    isSmallScreen: boolean
  ) {
    const dialogRef = dialog.open<
      ShareAllOffersDialogComponent,
      ShareAllOffersDialogData,
      ShareAllOffersDialogResponse[]
    >(ShareAllOffersDialogComponent, {
      panelClass: isSmallScreen ? 'full-dialog' : '',
      data: data,
      disableClose: true,
      width: '1000px',
      maxHeight: '100vh',
    });

    dialogRef.componentInstance.openPdf.subscribe(async (response) => {
      pdfService.openPDF(
        await pdfService.generatePriceListPdf(
          response.data.priceListItemLines,
          response.data.offer.vendor,
          response.includeInactiveProducts
        )
      );
    });

    dialogRef.afterClosed().subscribe((response) => {
      dialogRef.componentInstance.openPdf.unsubscribe();

      if (response) {
        const action = OfferFeedback.shareOffer;
        Promise.all(
          response
            .filter((r) => !!r && r.relationshipIds.length > 0)
            .map(async (r) => {
              mainPageLoadService.start(LoadingFeedback.ON_SHARING_OFFER);
              return offerFacade.shareOffer({
                offerId: r.offerId,
                relationshipIds: r.relationshipIds,
                message: r.message,
                pdfBase64: r.attachPdf
                  ? await pdfService.getBase64(
                      await pdfService.generatePriceListPdf(
                        data[r.offerId].priceListItemLines,
                        data[r.offerId].offer.vendor,
                        r.includeInactiveProducts
                      )
                    )
                  : null,
              });
            })
        )
          .then(() => {
            snackbarService.showSuccess(action);
          })
          .catch((e) => {
            snackbarService.showError(action);
          })
          .finally(() => mainPageLoadService.end());
      }
    });
  }

  export function getOfferSharingIssues(
    offer: Offer & WithId,
    customers: CustomerAccess.Customers & WithId,
    deliveryByRelationship: DeliveryByRelationshipId
  ): ShareOfferIssuesMessages[] {
    const issues: ShareOfferIssuesMessages[] = [];

    const publicDeliveries = offer.vendorPublicDeliveries.filter(
      (delivery) => delivery.isPublic
    );

    if (!isOfferActive(offer)) {
      issues.push(ShareOfferIssuesMessages.NOT_ACTIVE);
    }
    if (!hasAtLeastOneCustomer(customers)) {
      issues.push(ShareOfferIssuesMessages.NO_CUSTOMER);
    }
    if (!hasAtLeastOneProduct(offer)) {
      issues.push(ShareOfferIssuesMessages.NO_PRODUCT);
    }
    if (
      PickupUtils.getPickupAvailability(offer.pickups, {
        upperBound: offer.expirationDate,
      }) !== DatesAvailability.AVAILABLE &&
      DeliveryUtils.getDeliveryAvailability(
        customers,
        deliveryByRelationship,
        publicDeliveries,
        {
          upperBound: offer.expirationDate,
        }
      ) !== DatesAvailability.AVAILABLE
    ) {
      issues.push(ShareOfferIssuesMessages.NO_DELIVERY_OR_NO_PICKUP);
    }

    return issues;
  }

  export function hasAtLeastOneCustomer(
    customers: CustomerAccess.Customers & WithId
  ): boolean {
    return customers.allowed.length > 0;
  }

  export function hasAtLeastOneProduct(offer: Offer & WithId): boolean {
    return offer.offerItems.length > 0;
  }

  export function shareOfferToSelectedCustomer(
    data: MultiShareToCustomersDialogData,
    dialog: MatDialog,
    mainPageLoadService: MainPageLoadService,
    offerFacade: VendorOfferFacade,
    snackbarService: SnackbarService
  ) {
    const dialogRef = dialog.open<
      MultiShareToCustomersDialogComponent,
      MultiShareToCustomersDialogData,
      MultiShareToCustomersDialogResponse
    >(MultiShareToCustomersDialogComponent, {
      width: '600px',
      data: data,
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        const action = OfferFeedback.shareOffer;
        mainPageLoadService.start(LoadingFeedback.ON_SHARING_OFFER);
        Promise.all(
          _.map(
            Object.entries(data.shareToCustomers),
            ([offerId, relationships]) =>
              offerFacade.shareOffer({
                offerId: offerId,
                relationshipIds: relationships.map((r) => r.relationshipId),
                message: response.message,
              })
          )
        )
          .then(() => {
            snackbarService.showSuccess(action);
          })
          .catch((e) => {
            snackbarService.showError(action);
          })
          .finally(() => mainPageLoadService.end());
      }
    });
  }

  export function updateOfferExpirationDate(
    offer: Partial<Offer> & WithId,
    offerFacade: VendorOfferFacade,
    snackbarService: SnackbarService,
    dialog: MatDialog,
    onCancel?: () => void
  ) {
    dialog
      .open<
        OfferExpirationDialogComponent,
        any,
        OfferExpirationDialogComponentData
      >(OfferExpirationDialogComponent, {
        width: '400px',
        data: {
          expirationDate: offer.expirationDate ? offer.expirationDate : null,
        },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          updateOffer(
            {
              ...offer,
              expirationDate: data.expirationDate,
              forDeliveryBy: data.expirationDate,
            },
            offerFacade,
            snackbarService,
            OfferFeedback.update
          );
        } else if (onCancel) {
          onCancel();
        }
      });
  }

  export function updateOfferIsActive(
    offer: Partial<Offer> & WithId,
    onCancel: () => void,
    offerFacade: VendorOfferFacade,
    snackbarService: SnackbarService,
    dialog: MatDialog
  ) {
    if (
      offer.isActive &&
      (!offer.expirationDate || isBefore(offer.expirationDate, new Date()))
    ) {
      updateOfferExpirationDate(
        offer,
        offerFacade,
        snackbarService,
        dialog,
        onCancel
      );
    } else {
      updateOffer(
        offer,
        offerFacade,
        snackbarService,
        !offer.isActive
          ? OfferFeedback.availabilityUpdateToFalse
          : OfferFeedback.availabilityUpdateToTrue
      );
    }
  }

  export function updateOffer(
    offer: Partial<Offer> & WithId,
    offerFacade: VendorOfferFacade,
    snackbarService: SnackbarService,
    action: string
  ) {
    offerFacade
      .updateItem(offer)
      .then(() => {
        snackbarService.showSuccess(action);
      })
      .catch((e) => snackbarService.showError(action));
  }

  export function getPaymentTerms(offer: Offer, relationshipId: string) {
    if (!offer) {
      return undefined;
    }

    if (!relationshipId) {
      return offer.defaultPaymentTermsInDays;
    }

    const override = !LangUtils.nullOrUndefined(offer?.paymentTermsOverrides)
      ? offer.paymentTermsOverrides[relationshipId]
      : undefined;
    return !LangUtils.nullOrUndefined(override)
      ? override
      : offer.defaultPaymentTermsInDays;
  }

  export function getPaymentMethods(offer: Offer) {
    if (!offer || !offer.paymentMethods || offer.paymentMethods.length === 0) {
      return undefined;
    }
    return offer.paymentMethods;
  }

  export function getPersonalizedMinOrder(
    offer: Offer,
    relationshipId: string,
    isOverridden: boolean
  ): Money | undefined {
    if (!offer || !relationshipId || isOverridden) {
      return undefined;
    }

    return !LangUtils.nullOrUndefined(offer?.minOrderOverrides)
      ? offer.minOrderOverrides[relationshipId]
      : undefined;
  }

  export function getClientDiscount(offer: Offer, relationshipId: string) {
    if (!offer || !relationshipId) {
      return undefined;
    }

    return !LangUtils.nullOrUndefined(offer?.clientDiscountOverrides)
      ? offer.clientDiscountOverrides[relationshipId]
      : undefined;
  }

  export function updateOffersAfterUrgentChange(
    inventoryLine: InventoryLine,
    updatedItem: Partial<InventoryItem> & WithId,
    priceListItemsLines: Dictionary<PriceListItemLine[]>
  ): Pick<Offer, 'priceListId' | 'offerItems'>[] {
    const priceListItems: (Partial<PriceListItem> & WithId)[] =
      extractPriceListsItems(inventoryLine);

    return _.map(priceListItems, (pliUpdated) => {
      return {
        priceListId: pliUpdated.priceListId,
        offerItems: priceListItemsLines[pliUpdated.priceListId]
          .filter((pli) => isActiveInPriceListOfUpdatedFormat(pli, pliUpdated))
          .map((line) => updateOfferItem(line, pliUpdated, updatedItem)),
      };
    });
  }

  export function displayPaymentMethods(paymentMethods: PaymentMethod[]) {
    if (!(paymentMethods && paymentMethods.length != 0)) return '';

    const translatedMethods = paymentMethods.map((method) =>
      translate<string>('enums.payment-methods.' + method)
    );

    return translatedMethods.join(', ');
  }

  export function selectApplicableDeliveries(
    publicOffer: Offer,
    deliveries: (Delivery & WithId)[],
    zoneCodes: string[],
    buyerOrganization: Organization & WithId
  ): (Delivery & WithId)[] {
    const applicableDeliveries: (Delivery & WithId)[] = [];

    const assignedDelivery = deliveries.find((delivery) => {
      return (
        delivery.organizationId === publicOffer.vendor.organizationId &&
        delivery.visibleForOrganizations.includes(buyerOrganization.id)
      );
    });

    if (assignedDelivery) {
      applicableDeliveries.push(assignedDelivery);
    }

    const applicablePublicDeliveries = _.filter(
      publicOffer.vendorPublicDeliveries,
      (pd) => {
        return _.intersection(pd.deliveryZoneCodes, zoneCodes).length > 0;
      }
    );

    return _.uniqBy(
      [...applicableDeliveries, ...applicablePublicDeliveries],
      (d: Delivery & WithId) => d.id
    );
  }

  function extractPriceListsItems(
    inventoryLine: InventoryLine
  ): (Partial<PriceListItem> & WithId)[] {
    return _.map(inventoryLine.priceListItemByPriceList, (pli) => {
      const restOfPli: Omit<PriceListItem & WithId, 'inventoryItemId'> = _.omit(
        pli,
        'inventoryItemId'
      );
      return {
        ...restOfPli,
        id: pli.id,
      };
    });
  }

  function updateOfferItem(
    line: PriceListItemLine,
    pliUpdated: Partial<PriceListItem> & WithId,
    updatedItem: Partial<InventoryItem> & WithId
  ): OfferItem {
    const offerItem: OfferItem = {
      quantity: line.inventoryItem.isAvailable
        ? line.inventoryItem.quantity
        : 0,
      price: line.priceListItem.price,
      product: line.product,
      format: line.format,
    };

    if (line.priceListItem.isPromo && line.inventoryItem?.reducedPrice?.value) {
      offerItem.price = line.inventoryItem.reducedPrice.value;
      offerItem.regularPrice = line.inventoryItem.basePrice;
    }

    if (pliUpdated.id === line.priceListItem.id) {
      offerItem.isUrgent = updatedItem.isUrgent;
    } else {
      if (line.inventoryItem.isUrgent) {
        offerItem.isUrgent = line.inventoryItem.isUrgent;
      }
    }

    return offerItem;
  }

  function isActiveInPriceListOfUpdatedFormat(
    currentPriceListItem: PriceListItemLine,
    updatedFormat: Partial<PriceListItem> & WithId
  ): boolean {
    return updatedFormat.id === currentPriceListItem.priceListItem.id
      ? updatedFormat.isActive
      : currentPriceListItem.priceListItem.isActive;
  }

  export function convertProductsAndFormatsToOfferItems(
    products: (Product & WithId)[],
    formats: (Format & WithId)[]
  ): OfferItem[] {
    const offerItems: OfferItem[] = [];
    products.forEach((product) => {
      formats.forEach((format) => {
        if (format.productId === product.id) {
          offerItems.push({
            product: product,
            format: format,
            quantity: format.quantity,
            price: { amount: 0, currency: 'CAD' },
          });
        }
      });
    });
    return offerItems;
  }
}
