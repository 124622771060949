import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AuthUser } from '@arrivage-auth/model/auth-data.model';
import {
  UnsavedChangesDialogComponent,
  UnsavedChangesDialogData,
} from '@arrivage-components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { environment } from '@arrivage-environments/environment';
import { DirtyCheckService } from '@arrivage-services/dirty-check.service';
import { PictureService } from '@arrivage-services/picture.service';
import { ContextFacade } from '@arrivage-store/api/context.facade';
import {
  Notification,
  Organization,
  OrganizationSettings,
  User,
  WithId,
} from '@arrivage/model/dist/src/model';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  readonly environment = environment;
  DEFAULT_PHOTO_URL = PictureService.DEFAULT_PHOTO_URL;

  @Input()
  authUser: AuthUser;

  @Input()
  user: User & WithId;

  @Input()
  activeOrganization: Organization & WithId;

  @Input()
  settings: OrganizationSettings;

  @Input()
  notifications: (Notification & WithId)[];

  @Output()
  sidenavToggle = new EventEmitter<void>();

  @Output()
  notificationRead = new EventEmitter<string>();

  constructor(
    private dialog: MatDialog,
    private contextFacade: ContextFacade,
    private dirtyCheckService: DirtyCheckService
  ) {}

  onToggleSidenav() {
    this.sidenavToggle.emit();
  }

  onLogout() {
    if (this.dirtyCheckService.isDirty) {
      const dialogRef = this.dialog.open<
        UnsavedChangesDialogComponent,
        UnsavedChangesDialogData,
        boolean
      >(UnsavedChangesDialogComponent, {
        data: {
          overrideLeaveTransKey: 'logout-leave',
        },
      });
      dialogRef.afterClosed().subscribe((value) => {
        if (value) {
          this.dirtyCheckService.resetDirtiness();
          this.contextFacade.logout();
        }
      });
    } else {
      this.contextFacade.logout();
    }
  }

  onNotificationRead(notificationId: string) {
    this.notificationRead.emit(notificationId);
  }

  /**
   * Boolean to display language select in the toolbar for dev only
   * :)
   */
  showLanguageSelectForDev(): boolean {
    return (environment as any)?.development?.showLanguage || false;
  }
}
