import _ from 'lodash';

import {
  Organization,
  OrganizationSummary,
  RelationshipInfo,
  TransactionDocument,
} from '@arrivage/model/dist/src/model';

export namespace RelationsLogic {
  export enum RelationField {
    CUSTOMER = 'customer',
    VENDOR = 'vendor',
  }

  export interface CustomerInfo extends OrganizationSummary {
    organizationId?: string;
  }

  export function extractOrganizationSummaryFromCustomer(
    value: RelationshipInfo
  ): CustomerInfo {
    const summary: CustomerInfo = Organization.extractSummary(value);
    if (value.organizationId) {
      summary.organizationId = value.organizationId;
    }
    return summary;
  }

  export function extractRelationsOrganizationsFromTransactionDocument(
    documents: TransactionDocument[],
    relationField: RelationField
  ): OrganizationSummary[] {
    const map: _.Dictionary<TransactionDocument> = _.keyBy(
      documents,
      relationField + '.name'
    );

    return Object.values(map).map((x) => {
      return x[relationField];
    });
  }
}
