export const environment = {
  name: 'production',
  production: true,
  demo_org_account: ['sLpoKC2BNuFpHXnGr3Sk', 'YiTuoGRDj4cC9OsT0jii'],
  default_photo_url:
    'https://firebasestorage.googleapis.com/' +
    'v0/b/arrivage-280ad.appspot.com/o/default.png?alt=media&token=05f8f615-a68f-47a8-baee-1936960d42f7l',
  base_url: 'https://arrivage.pro',
  firebase: {
    apiKey: 'AIzaSyCqBvEjEpSDYaefz1f6BBeAKC-7IW1anZY',
    authDomain: 'arrivage-pro.firebaseapp.com',
    databaseURL: 'https://arrivage-pro.firebaseio.com',
    projectId: 'arrivage-pro',
    storageBucket: 'arrivage-pro.appspot.com',
    messagingSenderId: '418347608519',
    appId: '1:418347608519:web:6cddf50ffa06c87d17d1e2',
    measurementId: 'G-WWKHJXRXMB',
  },
};
