import { differenceInMinutes } from 'date-fns';

import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { translate } from '@jsverse/transloco';

import { CustomerAccess } from '@arrivage-customer-access/customer-access.model';
import { DeliveryUtils } from '@arrivage-distribution/common/utils/delivery.utils';
import { PickupUtils } from '@arrivage-distribution/vendor/utils/pickup.utils';
import {
  DatesAvailability,
  PriceListItemLine,
} from '@arrivage-price-lists/model/price-list.model';
import { DeliveryByRelationshipId } from '@arrivage-relationship/common/model/relationship.model';
import { LangUtils } from '@arrivage-util/lang.utils';
import {
  Delivery,
  Offer,
  RelationshipInfo,
  WithId,
} from '@arrivage/model/dist/src/model';
import _ from 'lodash';

export interface ShareToCustomersDialogData {
  customers: CustomerAccess.Customers;
  offer: Offer & WithId;
  deliveryByRelationship: DeliveryByRelationshipId;
  priceListItems: PriceListItemLine[];
  isTestWorkflow?: boolean;
}

export interface ShareToCustomersOutputResponse {
  includeInactiveProducts: boolean;
}

export interface ShareToCustomersDialogResponse
  extends ShareToCustomersOutputResponse {
  customerIds: string[];
  message: string;
  attachPdf: boolean;
}

@Component({
  selector: 'app-share-to-customers-dialog',
  templateUrl: './share-to-customers-dialog.component.html',
  styleUrls: ['./share-to-customers-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareToCustomersDialogComponent {
  readonly displayedColumns = [
    'select',
    'customers',
    'lastShareOfferTimestamp',
  ];

  dataSource = new MatTableDataSource<RelationshipInfo & WithId>();
  selection = new SelectionModel<RelationshipInfo & WithId>(true);
  attachPdf = true;
  includeInactiveProducts = true;

  form: UntypedFormGroup = this.fb.group({
    message: [null],
  });

  @Output()
  openPdf = new EventEmitter<ShareToCustomersOutputResponse>();

  @ViewChild(MatSort)
  set matSort(value: MatSort) {
    if (value) {
      this.dataSource.sort = value;
      this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    }
  }

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: ShareToCustomersDialogData,
    public dialogRef: MatDialogRef<
      ShareToCustomersDialogComponent,
      ShareToCustomersDialogResponse
    >
  ) {
    this.dataSource.data = LangUtils.normalizedSortBy(
      this.data.customers?.allowed,
      (r) => r.name
    );
    this.selection = new SelectionModel<RelationshipInfo & WithId>(
      true,
      this.dataSource.data.filter((d) => this.isEligibleForSharing(d))
    );
  }

  get messageControl(): AbstractControl {
    return this.form.get('message');
  }

  get canShareOffer(): boolean {
    return (
      (this.isOfferActive &&
        this.hasAtLeastOneCustomer &&
        this.hasAtLeastOneProduct &&
        (this.arePickupOptionsAvailable || this.areDeliveryOptionsAvailable)) ||
      !!this.data.isTestWorkflow
    );
  }

  get isOfferActive(): boolean {
    return this.data.offer.isActive;
  }

  get hasAtLeastOneCustomer(): boolean {
    return this.data.customers.allowed.length > 0;
  }

  get hasAtLeastOneProduct(): boolean {
    return this.data.offer.offerItems.length > 0;
  }

  get someHaveDeliveryIssues(): boolean {
    return (
      this.hasAtLeastOneCustomer &&
      this.data.customers.allowed.some((c) => !this.isEligibleForSharing(c))
    );
  }

  get someHaveOnlyPickup(): boolean {
    return (
      this.hasAtLeastOneCustomer &&
      this.data.customers.allowed.some((c) => this.isOnlyPickupAvailable(c))
    );
  }

  get arePickupOptionsAvailable(): boolean {
    return (
      PickupUtils.getPickupAvailability(this.data.offer.pickups, {
        upperBound: this.data.offer.expirationDate,
      }) === DatesAvailability.AVAILABLE
    );
  }

  get areDeliveryOptionsAvailable(): boolean {
    return (
      DeliveryUtils.getDeliveryAvailability(
        this.data.customers,
        this.data.deliveryByRelationship,
        this.publicDeliveries,
        {
          upperBound: this.data.offer.expirationDate,
        }
      ) === DatesAvailability.AVAILABLE
    );
  }

  get specifyErrorMessage(): string {
    const deliveryAvailability = DeliveryUtils.getDeliveryAvailability(
      this.data.customers,
      this.data.deliveryByRelationship,
      this.publicDeliveries,
      {
        upperBound: this.data.offer.expirationDate,
      }
    );

    const pickupAvailability: DatesAvailability =
      PickupUtils.getPickupAvailability(this.data.offer.pickups, {
        upperBound: this.data.offer.expirationDate,
      });

    if (deliveryAvailability === DatesAvailability.NONE_WITH_DELAY) {
      if (pickupAvailability === DatesAvailability.NONE_WITH_DELAY) {
        return translate('priceLists.system-message.delivery-pickup-delay');
      } else if (pickupAvailability === DatesAvailability.NONE) {
        return translate(
          'priceLists.system-message.delivery-delay-pickup-no-dates'
        );
      }
    } else if (deliveryAvailability === DatesAvailability.NONE) {
      if (pickupAvailability === DatesAvailability.NONE) {
        return translate('priceLists.system-message.delivery-pickup-no-dates');
      } else if (pickupAvailability === DatesAvailability.NONE_WITH_DELAY) {
        return translate(
          'priceLists.system-message.delivery-no-dates-pickup-delay'
        );
      }
    }

    return translate('priceLists.system-message.delivery-pickup-no-dates');
  }

  get publicDeliveries(): (Delivery & WithId)[] {
    return this.data.offer.vendorPublicDeliveries.filter(
      (delivery) => delivery.isPublic
    );
  }

  trackById(index: number, item: RelationshipInfo & WithId) {
    return item.id;
  }

  allHaveSharingIssues(): boolean {
    return (
      this.hasAtLeastOneCustomer &&
      this.data.customers.allowed.every((c) => {
        return !this.isEligibleForSharing(c);
      })
    );
  }

  onOpenPdf() {
    this.openPdf.emit({
      includeInactiveProducts: this.attachPdf
        ? this.includeInactiveProducts
        : true,
    });
  }

  select(row: RelationshipInfo & WithId) {
    if (this.isEligibleForSharing(row)) {
      this.selection.toggle(row);
    }
  }

  async share() {
    this.dialogRef.close({
      customerIds: this.data.isTestWorkflow
        ? []
        : this.selection.selected.map((selected) => selected.relationshipId),
      message: this.messageControl.value ? this.messageControl.value : '',
      attachPdf: this.attachPdf,
      includeInactiveProducts: this.includeInactiveProducts,
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.filter((row) =>
      this.isEligibleForSharing(row)
    ).length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data
          .filter((row) => this.isEligibleForSharing(row))
          .forEach((row) => this.selection.select(row));
  }

  isEligibleForSharing(relationship: RelationshipInfo & WithId) {
    return (
      (relationship.deliveryId &&
        DeliveryUtils.hasOpenDeliveryDate(
          this.data.deliveryByRelationship[relationship.relationshipId],
          { upperBound: this.data.offer.expirationDate }
        )) ||
      _.some(this.publicDeliveries, (delivery) =>
        DeliveryUtils.hasOpenDeliveryDate(delivery, {
          upperBound: this.data.offer.expirationDate,
        })
      ) ||
      this.arePickupOptionsAvailable
    );
  }

  isDeliveryIssue(relationship: RelationshipInfo & WithId) {
    return (
      relationship.deliveryId &&
      !DeliveryUtils.hasOpenDeliveryDate(
        this.data.deliveryByRelationship[relationship.relationshipId],
        { upperBound: this.data.offer.expirationDate }
      )
    );
  }

  isOnlyPickupAvailable(relationship: RelationshipInfo & WithId) {
    return (
      this.arePickupOptionsAvailable &&
      (!relationship.deliveryId ||
        !DeliveryUtils.hasOpenDeliveryDate(
          this.data.deliveryByRelationship[relationship.relationshipId],
          { upperBound: this.data.offer.expirationDate }
        ))
    );
  }

  elapsedTimeInMinutes(lastShareOfferTimestamp: number) {
    return lastShareOfferTimestamp
      ? differenceInMinutes(new Date().getTime(), lastShareOfferTimestamp)
      : Number.MAX_SAFE_INTEGER;
  }

  elapsedTimeLessThanHour(lastShareOfferTimestamp: number) {
    return this.elapsedTimeInMinutes(lastShareOfferTimestamp) < 60;
  }

  someShareIsLessThanHour() {
    if (!this.selection?.selected.length) {
      return false;
    }
    return this.selection?.selected.some((row) =>
      this.elapsedTimeLessThanHour(row.lastShareOfferTimestamp)
    );
  }

  everyShareIsLessThanHour() {
    if (!this.selection?.selected.length) {
      return false;
    }
    return this.selection?.selected.every((row) =>
      this.elapsedTimeLessThanHour(row.lastShareOfferTimestamp)
    );
  }

  /**
   * Returns the remaining time to wait before sharing the offer again
   * @returns
   */
  remainingTimeToWait(): string {
    const elapsedTimesInMinutes = _.map(this.selection?.selected, (row) =>
      this.elapsedTimeInMinutes(row.lastShareOfferTimestamp)
    );

    // The time to wait is the difference between
    // 60 minutes and the maximum time to wait among the selected customers
    return (60 - _.maxBy(elapsedTimesInMinutes)).toString();
  }

  onAttachPdfChange(checked: boolean) {
    this.attachPdf = checked;
    this.includeInactiveProducts = checked;
  }

  private sortingDataAccessor(
    customer: RelationshipInfo & WithId,
    headerId: string
  ): string | number {
    switch (headerId) {
      case 'customers':
        return LangUtils.normalizeString(customer.name);
      case 'lastShareOfferTimestamp':
        return customer.lastShareOfferTimestamp
          ? customer.lastShareOfferTimestamp
          : 1;
      default:
        return customer[headerId] ? customer[headerId] : 1;
    }
  }
}
