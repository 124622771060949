import { PictureUtils } from 'src/app/util/picture.utils';

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import {
  PictureCropperEditorDialogComponent,
  PictureCropperEditorDialogData,
} from '../picture-cropper-editor-dialog/picture-cropper-editor-dialog.component';

@Component({
  selector: 'app-upload-picture',
  templateUrl: './upload-picture.component.html',
  styleUrls: ['./upload-picture.component.scss'],
})
export class UploadPictureComponent {
  private pictureFile: File;

  form = this.fb.group({
    fileInput: [null],
  });

  pictureUrl: SafeUrl | string = null;
  showError = false;

  @Input()
  maintainCropperAspectRatio = true;

  @Input()
  set currentPictureURL(value: string | SafeUrl) {
    this.pictureUrl = value;
  }

  @Input()
  imageCssClass: string;

  @Input()
  set hasDirectEdit(value: boolean) {
    this._allowDirectEdit = value;
  }
  get allowDirectEdit(): boolean {
    return this._allowDirectEdit && !this.isReadOnly;
  }
  private _allowDirectEdit = false;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  aspectRatio: number;

  @Output()
  pictureChanges = new EventEmitter<{
    file: File;
    pictureUrl: string | SafeUrl;
  }>();

  constructor(
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private fb: UntypedFormBuilder
  ) {}

  onPictureChange(event: any): void {
    const selectedPicture = event.target.files[0] as File;
    if (selectedPicture) {
      if (selectedPicture.type.includes('image')) {
        if (event) {
          this.dialog
            .open<
              PictureCropperEditorDialogComponent,
              PictureCropperEditorDialogData,
              string
            >(PictureCropperEditorDialogComponent, {
              data: {
                imageChangedEvent: event,
                outputType: PictureUtils.extractPictureType(
                  selectedPicture.type
                ),
                unsupportedFormat: false,
                maintainAspectRatio: this.maintainCropperAspectRatio,
                aspectRatio: this.aspectRatio,
              },
            })
            .afterClosed()
            .subscribe((croppedImage) => {
              if (croppedImage) {
                const resizedImage = PictureUtils.base64ToFile(
                  croppedImage,
                  selectedPicture
                );
                this.pictureUrl = this.sanitizer.bypassSecurityTrustUrl(
                  URL.createObjectURL(resizedImage)
                );
                this.pictureFile = resizedImage;
                this.pictureChanges.emit({
                  file: resizedImage,
                  pictureUrl: this.pictureUrl,
                });
              }
              this.form.get('fileInput').reset();
            });
          this.showError = false;
        }
      } else {
        this.showError = true;
      }
    }
  }

  getPicture(): File {
    return this.pictureFile;
  }

  deletePhoto() {
    this.pictureUrl = null;
    this.pictureFile = null;
    this.pictureChanges.emit({
      file: this.pictureFile,
      pictureUrl: this.pictureUrl,
    });
  }
}
