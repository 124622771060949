<section fxLayout="column" class="layout">
  <header>
    <ng-content select="app-header"></ng-content>
  </header>

  <main fxFlex fxLayout="column">
    <section fxFlex>
      <ng-content></ng-content>
    </section>
  </main>
</section>
