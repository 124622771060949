<ng-container *transloco="let t; read: 'components.filtered-date-range-picker'">
  <section
    fxLayout="row wrap"
    [fxLayoutAlign]="fillSpace ? 'space-between center' : 'start start'"
  >
    <app-date-range-picker
      [label]="dateRangePickerLabel"
      [customTooltipText]="dateRangePickerCustomTooltipText"
      [dateRange]="defaultDateRange"
      [dateRangeOption]="defaultDateRangeOption"
      [pageName]="pageName"
      (dateRangeEmitter)="onDateRangeChanges($event)"
    ></app-date-range-picker>

    <mat-form-field appearance="outline">
      <mat-label> {{ t('date-type-selection-label') }}... </mat-label>
      <mat-select
        mat-input
        (selectionChange)="onDateTypeChanges($event.value)"
        [value]="dateType"
      >
        <mat-option *ngFor="let type of DATE_TYPES" [value]="type">
          {{ t((type | kebabCase) + '-date-range') }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </section>
</ng-container>
